import React from 'react';

import { Route, Redirect } from 'react-router-dom';
import { isLoggin } from '../util/IsLoggin';

const MyRoute = ({ component: Component, isRestricted = false, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggin() ? <Component {...props} /> : <Redirect to='/manage/login' />
      }
    />
  );
};

export default MyRoute;
