import React, { useState, useEffect } from "react";
// import { isMobile } from 'react-device-detect';
import axios from "axios";
import {
  message,
  Table,
  Input,
  Popconfirm,
  Form,
  Row,
  Col,
  Button,
  Modal,
} from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
const EmailPlans = () => {
  const { Search } = Input;
  const [tableData, setTableData] = useState([]),
    [selectedDomain, setSelectedDomain] = useState([]),
    [tableLoading, setTableLoad] = useState(true),
    [isModalVisible, setIsModalVisible] = useState(false),
    [isEditModalVisible, setIsEditModalVisible] = useState(false),
    [hasSelected, setHasSelected] = useState(false);

  const [form] = Form.useForm();
  const [editform] = Form.useForm();
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const columns = [
      {
        title: "id",
        dataIndex: "id",
        width: "3%",
        editable: false,
        render: (text) => <center>{text}</center>,
      },
      {
        title: "Plan Name",
        dataIndex: "plan_name",
        width: "12%",
        align: "center",
        editable: true,
      },
      {
        title: "Domain Alias",
        dataIndex: "domain_alias_number",
        width: "8%",
        align: "right",
        editable: true,
      },
      {
        title: "Mailbox Number",
        dataIndex: "mailboxes_number",
        width: "10%",
        align: "right",
        editable: true,
      },
      {
        title: "Domain Quota (MB)",
        dataIndex: "domain_max_quota",
        width: "12%",
        align: "right",
      },
      {
        title: "Mailbox Quota (MB)",
        dataIndex: "default_mailbox_quota",
        width: "12%",
        align: "right",
      },
      {
        title: "Rate per second)",
        dataIndex: "rate_per_second",
        width: "12%",
        align: "right",
      },
      {
        title: "Suspend after (days)",
        dataIndex: "suspend_date",
        width: "15%",
        align: "right",
      },
      {
        title: "Delete after Suspend (days)",
        dataIndex: "delete_date",
        width: "15%",
        align: "right",
        editable: true,
      },
    ],
    onSelectChange = (selectedDomain) => {
      setSelectedDomain({ selectedDomain });
      setHasSelected(selectedDomain.length > 0);
    },
    domainSelection = {
      selectedDomain,
      onChange: onSelectChange,
    };
  const handleCreateDomainModal = () => {
      setIsModalVisible(true);
    },
    massCancel = (e) => {
      message.success("Canceled Email plan delete");
    };
  const handleDomainSearch = async (value) => {
    try {
      setTableLoad(true);
      if (value.length === 0) {
        const fetchTableData = await axios.get(
          "https://multidomain.zonedweb.com/api/email/plan",
          config
        );
        console.log(fetchTableData);
        if (fetchTableData.data.success === true) {
          setTableData([...fetchTableData.data.result]);
          setTableLoad(false);
        }
      }
      if (value.length > 0) {
        const fetchTableData = await axios.get(
          `https://multidomain.zonedweb.com/api/email/plan?search=${value}`,
          config
        );
        console.log(fetchTableData);
        if (fetchTableData.data.success === true) {
          setTableData([...fetchTableData.data.result]);
          setTableLoad(false);
        }
      }
    } catch (err) {
      console.log(err);

      message.error(
        `got error while fetching email plan list with Error: ${err}`
      );
    }
  };
  const confirmMassDelete = async (e) => {
    try {
      for (let val of e) {
        const addDomainResult = await axios.delete(
          "https://multidomain.zonedweb.com/api/email/plan",
          {
            data: { id: val },
            ...config,
          }
        );
        if (addDomainResult.data.success === true) {
          setTableData((prev) => {
            const result = prev.filter((v) => v.id !== val);
            return [...result];
          });
        }
      }
      setSelectedDomain([]);
    } catch (err) {
      console.log(err);
      message.error(`got error deleting email plan: ${err}`);
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const onFinish = async (e) => {
    try {
      const body = JSON.stringify(e);
      const addDomainResult = await axios.post(
        "https://multidomain.zonedweb.com/api/email/plan",
        body,
        config
      );
      if (
        !addDomainResult ||
        !addDomainResult.data ||
        addDomainResult.data.success !== true
      ) {
        message.error(addDomainResult.data.result);
      }
      if (
        addDomainResult &&
        addDomainResult.data &&
        addDomainResult.data.success === true
      ) {
        setTableData((prev) => [
          {
            id: addDomainResult.data.result,
            plan_name: e.plan_name,
            domain_alias_number: e.domain_alias_number,
            mailboxes_number: e.mailboxes_number,
            default_mailbox_quota: e.default_mailbox_quota,
            domain_max_quota: e.domain_max_quota,
            suspend_date: e.suspend_date,
            delete_date: e.delete_date,
          },
          ...prev,
        ]);
        form.resetFields();
        setIsModalVisible(false);
      }
    } catch (err) {
      console.log(err);

      message.error(`got error adding email plan: ${err}`);
    }
  };

  useEffect(() => {
    const kickStart = async () => {
      try {
        const fetchTableData = await axios.get(
          "https://multidomain.zonedweb.com/api/email/plan",
          config
        );
        console.log(fetchTableData);
        if (fetchTableData.data.success === true) {
          setTableData([...fetchTableData.data.result]);
          setTableLoad(false);
        }
      } catch (err) {
        console.log(err);

        message.error(
          `got error while fetching email plan list with Error: ${err}`
        );
      }
    };
    kickStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const showEditModal = (e) => {
    console.log(e);
    editform.setFieldsValue({ ...e });
    setIsEditModalVisible(true);
  };
  const cancleHandler = (e) => {
    setIsEditModalVisible(false);
  };
  const handleEditCancel = (e) => {
    setIsEditModalVisible(false);
  };
  const onEditFinish = async (e) => {
    try {
      const body = JSON.stringify(e);
      const addDomainResult = await axios.put(
        "https://multidomain.zonedweb.com/api/email/plan",
        body,
        config
      );
      if (
        !addDomainResult ||
        !addDomainResult.data ||
        addDomainResult.data.success !== true
      ) {
        message.error(addDomainResult.data.result);
      }
      if (
        addDomainResult &&
        addDomainResult.data &&
        addDomainResult.data.success === true
      ) {
        setTableData((prev) => {
          for (let val in prev) {
            if (prev[val].id === e.id) {
              prev[val] = { ...e };
            }
          }
          return [...prev];
        });
        editform.resetFields();
        setIsEditModalVisible(false);
      }
    } catch (err) {
      console.log(err);

      message.error(`got error editing email plan: ${err}`);
    }
  };
  return (
    <>
      <Modal
        title='Edit Email Plan'
        visible={isEditModalVisible}
        onOk={handleOk}
        onCancel={handleEditCancel}
        footer={[]}
      >
        <Form
          name='editEmailPlan'
          labelCol={{ span: 11 }}
          wrapperCol={{ span: 13 }}
          onFinish={onEditFinish}
          form={editform}
          autoComplete='off'
        >
          <Form.Item
            label='id'
            name='id'
            rules={[
              { required: true, message: "Please input Email Plan name!" },
            ]}
            style={{ display: "none" }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Plan Name'
            name='plan_name'
            rules={[
              { required: true, message: "Please input Email Plan name!" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label='Number Domain Alias Allow'
            name='domain_alias_number'
            rules={[
              {
                required: true,
                message: "Please input number of Domain Alias Allowed",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Plan mailbox number'
            name='mailboxes_number'
            rules={[
              {
                required: true,
                message: "Please input Maximum allowed of plan mailbox number!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Default mailbox quota (MB)'
            name='default_mailbox_quota'
            rules={[
              {
                required: true,
                message: "Please input default mailbox quota in MB",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Domain maximum quota (MB)'
            name='domain_max_quota'
            rules={[
              {
                required: true,
                message: "Please input Maximum allowed of domain quota in MB",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Sending rate per second'
            name='rate_per_second'
            rules={[
              {
                required: true,
                message: "Please input Maximum mail sending rate per second",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Suspend after (days)'
            name='suspend_date'
            rules={[
              {
                required: true,
                message:
                  "Please input how many days to suspend the domain from the time of creation",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Delete after suspend (days) '
            name='delete_date'
            rules={[
              {
                required: true,
                message:
                  "Please input how many days domain will be deleted from the time of suspend",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button
              type='primary'
              htmlType='submit'
              style={{ marginRight: "10px" }}
            >
              Save
            </Button>
            <Button type='danger' onClick={cancleHandler}>
              Cancal
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title='Add new Email Plan'
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form
          name='addEmailPlan'
          labelCol={{ span: 11 }}
          wrapperCol={{ span: 13 }}
          onFinish={onFinish}
          form={form}
          autoComplete='off'
        >
          <Form.Item
            label='Plan Name'
            name='plan_name'
            rules={[
              { required: true, message: "Please input Email Plan name!" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label='Number Domain Alias Allow'
            name='domain_alias_number'
            rules={[
              {
                required: true,
                message: "Please input number of Domain Alias Allowed",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Plan mailbox number'
            name='mailboxes_number'
            rules={[
              {
                required: true,
                message: "Please input Maximum allowed of plan mailbox number!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Default mailbox quota (MB)'
            name='default_mailbox_quota'
            rules={[
              {
                required: true,
                message: "Please input default mailbox quota in MB",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Domain maximum quota (MB)'
            name='domain_max_quota'
            rules={[
              {
                required: true,
                message: "Please input Maximum allowed of domain quota in MB",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Sending rate per second'
            name='rate_per_second'
            rules={[
              {
                required: true,
                message: "Please input Maximum mail sending rate per second",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Suspend after (days)'
            name='suspend_date'
            rules={[
              {
                required: true,
                message:
                  "Please input how many days to suspend the domain from the time of creation",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Delete after suspend (days) '
            name='delete_date'
            rules={[
              {
                required: true,
                message:
                  "Please input how many days domain will be deleted from the time of suspend",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Row justify='end' style={{ padding: "10px" }}>
        {selectedDomain.selectedDomain &&
          selectedDomain.selectedDomain.length > 0 && (
            <Col xs={10} sm={8} md={6} lg={13} xl={15}>
              <Popconfirm
                title={`Confirm Delete ${
                  selectedDomain.selectedDomain
                    ? selectedDomain.selectedDomain.length
                    : 0
                } Plan ?`}
                onConfirm={() =>
                  confirmMassDelete(selectedDomain.selectedDomain)
                }
                onCancel={massCancel}
                okText='Yes'
                cancelText='No'
              >
                <Button
                  type='primary'
                  shape='round'
                  icon={<CheckCircleOutlined />}
                  size='middle'
                  className='product-head-action-button'
                  disabled={!hasSelected}
                >
                  Delete Email Plan
                </Button>
              </Popconfirm>
              <span
                style={{ marginLeft: 8, color: "#c2136b", fontWeight: 600 }}
              >
                {hasSelected
                  ? `Selected ${selectedDomain.selectedDomain.length} Email Plan`
                  : ""}
              </span>
            </Col>
          )}

        <Col xs={10} sm={8} md={6} lg={4} xl={3}>
          <Button
            type='primary'
            onClick={() => handleCreateDomainModal({}, "new")}
          >
            Create New Email Plan
          </Button>
        </Col>
        <Col xs={14} sm={16} md={12} lg={7} xl={6}>
          <Search
            placeholder='Search Plan'
            enterButton
            name='searchPlan'
            onSearch={(e) => {
              handleDomainSearch(e);
            }}
          />
        </Col>
      </Row>
      <Table
        rowSelection={domainSelection}
        rowKey='id'
        dataSource={tableData}
        columns={columns}
        loading={tableLoading}
        pagination={{
          defaultPageSize: 30,
          total: tableData.length,
          showSizeChanger: false,
          position: ["bottomCenter"],
          hideOnSinglePage: true,
          size: "large",
          showQuickJumper: true,
        }}
        onRow={(record) => ({
          onClick: () => {
            showEditModal(record);
          },
        })}
        rowClassName={(record, index) => {
          return index % 2 === 0
            ? "table-row-light order-list-table-row"
            : "table-row-dark order-list-table-row";
        }}
      />
    </>
  );
};

export default EmailPlans;
