import React, { useState, useEffect } from "react";
// import { isMobile } from 'react-device-detect';
import axios from "axios";
import {
  message,
  Table,
  Input,
  Popconfirm,
  Form,
  Row,
  Col,
  Button,
  Modal,
  Spin,
  Select,
  Space,
} from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
function MagentoTemplate() {
  const { Search } = Input;
  const { Option } = Select;

  const [tableData, setTableData] = useState([]),
    [upgradeTable, setUpgradeTable] = useState([]),
    [selectedDomain, setSelectedDomain] = useState([]),
    [tableLoading, setTableLoad] = useState(true),
    [isModalVisible, setIsModalVisible] = useState(false),
    [magentoModal, setMagentoModal] = useState(false),
    [newMagentoResultModal, setNewMagentoResultModal] = useState(false),
    [upgradeMagentoResultModal, setUpgradeMagentoResultModal] = useState(false),
    [templateSourceList, setTemplateSourceList] = useState([]),
    [spinning, setSpinning] = useState(true),
    [resultResonse, setResultResponse] = useState({
      success: "pendding",
      cpanelUser: "pendding",
      cpanelPassword: "pendding",
      adminLink: "pendding",
      adminUsername: "pendding",
      adminPassword: "pendding",
      magentoLink: "pending",
    }),
    [magentoform] = Form.useForm(),
    [hasSelected, setHasSelected] = useState(false);

  const [form] = Form.useForm();

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const upgradeColumns = [
    { title: "Template Name", dataIndex: "templateName" },
    { title: "Result", dataIndex: "result" },
  ];
  const columns = [
      {
        title: "id",
        dataIndex: "id",
        width: "3%",

        render: (text) => <center>{text}</center>,
      },
      {
        title: "Sub domain",
        dataIndex: "sub_domain",
        width: "34%",
      },
      {
        title: "Theme Name",
        dataIndex: "theme_name",
        width: "12%",
      },
      {
        title: "Template Database Name",
        dataIndex: "mysql_db",
        width: "13%",

        render: (text) => <center>{text}</center>,
      },
      {
        title: "Source Name",
        dataIndex: "source_name",
        width: "8%",

        render: (text) => <center>{text}</center>,
      },
      {
        title: "Version",
        dataIndex: "magento_version",
        width: "6%",

        render: (text) => <center>{text}</center>,
      },
      {
        title: "Cleared",
        dataIndex: "cleared",
        width: "5%",

        render: (text) => <center>{text === 0 ? "No" : "Yes"}</center>,
      },
      {
        title: "Action",
        key: "action",
        width: "16%",
        align: "center",
        render: (text, record) => (
          <Space size='middle'>
            <Popconfirm
              title='Sure to Clean?'
              onConfirm={() => handleClean(record.id)}
            >
              <a>Clean</a>
            </Popconfirm>
            <Popconfirm
              title='Sure to Upgrade?'
              onConfirm={() => handleUpgrade(record.id)}
            >
              <a>Upgrade</a>
            </Popconfirm>
            <Popconfirm
              title='Sure to delete?'
              onConfirm={() => handleDelete(record.id)}
            >
              <a>Delete</a>
            </Popconfirm>
          </Space>
        ),
      },
    ],
    onSelectChange = (selectedDomain) => {
      setSelectedDomain({ selectedDomain });
      setHasSelected(selectedDomain.length > 0);
    },
    handleDelete = async (val) => {
      try {
        setTableLoad(true);
        const deleteTemplateResult = await axios.delete(
          `https://multidomain.zonedweb.com/api/magento?id=${val}`,
          config
        );
        if (deleteTemplateResult.data.success === true) {
          const fetchTableData = await axios.get(
            "https://multidomain.zonedweb.com/api/magento",
            config
          );

          if (fetchTableData.data.success === true) {
            setTableData([...fetchTableData.data.result]);
            setTableLoad(false);
          }
        } else {
          message.error(
            `cannot delete template subdomain with error: ${deleteTemplateResult.data.result}`
          );
        }
      } catch (err) {
        console.log(err);
        message.error(`got error while delete template: ${err}`);
      }
    },
    handleClean = async (val) => {
      try {
        setTableLoad(true);
        const clearTemplateResult = await axios.post(
          `https://multidomain.zonedweb.com/api/magento/clean`,
          { id: val },
          config
        );
        if (clearTemplateResult.data.success === true) {
          const fetchTableData = await axios.get(
            "https://multidomain.zonedweb.com/api/magento",
            config
          );

          if (fetchTableData.data.success === true) {
            setTableData([...fetchTableData.data.result]);
            setTableLoad(false);
          }
        } else {
          message.error(
            `cannot delete template subdomain with error: ${clearTemplateResult.data.result}`
          );
        }
      } catch (err) {
        console.log(err);
        message.error(`got error while delete template: ${err}`);
      }
    },
    domainSelection = {
      selectedDomain,
      onChange: onSelectChange,
    };
  const handleUpgrade = async (val) => {
    try {
      setTableLoad(true);
      const getMagentoLatestVersion = await axios.get(
        `https://multidomain.zonedweb.com/api/magento_version`
      );
      const magentoLatestVersion = getMagentoLatestVersion.data.result;

      const upgradeTemplateResult = await axios.post(
        `https://multidomain.zonedweb.com/api/magento_upgrade`,
        { id: val, version: getMagentoLatestVersion },
        config
      );
      if (upgradeTemplateResult.data.success === true) {
        const fetchTableData = await axios.get(
          "https://multidomain.zonedweb.com/api/magento",
          config
        );
        if (
          upgradeTemplateResult.data.result ===
          "Template already latest version"
        ) {
          message.success(
            `Template's already running latest version, no need to upgrade`
          );
        }
        if (fetchTableData.data.success === true) {
          setTableData([...fetchTableData.data.result]);
          setTableLoad(false);
        }
      } else {
        message.error(
          `cannot delete upgrade template with error: ${upgradeTemplateResult.data.result}`
        );
      }
    } catch (err) {
      console.log(err);
      message.error(`got error while upgrade template: ${err}`);
    }
  };
  const massCancel = (e) => {
    message.success("Canceled domain delete");
  };
  const handleDomainSearch = async (value) => {
    try {
      setTableLoad(true);
      if (value.length === 0) {
        const fetchTableData = await axios.get(
          "https://multidomain.zonedweb.com/api/magento",
          config
        );
        console.log(fetchTableData);
        if (fetchTableData.data.success === true) {
          setTableData([...fetchTableData.data.result]);
          setTableLoad(false);
        }
      }
      if (value.length > 0) {
        const fetchTableData = await axios.get(
          `https://multidomain.zonedweb.com/api/magento?search=${value}`,
          config
        );
        console.log(fetchTableData);
        if (fetchTableData.data.success === true) {
          setTableData([...fetchTableData.data.result]);
          setTableLoad(false);
        }
      }
    } catch (err) {
      console.log(err);

      message.error(`got error while fetching domain list with Error: ${err}`);
    }
  };
  const confirmMassDelete = async (e) => {
    try {
      setTableLoad(true);
      for (let val of e) {
        const deleteTemplateREsult = await axios.delete(
          `https://multidomain.zonedweb.com/api/magento?id=${val}`,
          config
        );
      }
      setSelectedDomain([]);
      setSelectedDomain([]);

      const fetchTableData = await axios.get(
        "https://multidomain.zonedweb.com/api/magento",
        config
      );

      if (fetchTableData.data.success === true) {
        setTableData([...fetchTableData.data.result]);
        setTableLoad(false);
      }
    } catch (err) {
      console.log(err);
      message.error(`got error deleting template: ${err}`);
    }
  };
  const adminlogin = async (e) => {
    const adminUrl = await axios.get(`https://${e.sub_domain}/grabshelfadmin`);
    console.log(adminUrl.request.res.responseUrl);
  };
  const onFinish = async (e) => {
    try {
      setResultResponse({
        success: "pendding",
        adminLink: "pendding",
        adminUsername: "pendding",
        adminPassword: "pendding",
        magentoLink: "pending",
      });
      setMagentoModal(false);
      setNewMagentoResultModal(true);
      const createMagentoResult = await axios.post(
        `https://multidomain.zonedweb.com/api/magento`,
        {
          subdir: e.domain,
          themename: e.themename,
          templateSource: e.template_source,
        },
        config
      );

      if (createMagentoResult.data.success === true) {
        setResultResponse({
          success: "success",
          adminLink: createMagentoResult.data.adminLink,
          adminUsername: createMagentoResult.data.adminUsername,
          adminPassword: createMagentoResult.data.adminPassword,
          magentoLink: createMagentoResult.data.magentoLink,
        });
        setSpinning(false);
        setTableLoad(true);
        const fetchTableData = await axios.get(
          "https://multidomain.zonedweb.com/api/magento",
          config
        );
        console.log(fetchTableData);
        if (fetchTableData.data.success === true) {
          setTableData([...fetchTableData.data.result]);
          setTableLoad(false);
        }
      } else {
        setResultResponse({
          success: false,
          adminLink: "pendding",
          adminUsername: "pendding",
          adminPassword: "pendding",
          magentoLink: "pending",
        });
        setNewMagentoResultModal(false);
        message.error(
          `Get errorr while creating new Magento Template: Domain already exists`
        );
      }

      magentoform.resetFields();
    } catch (err) {
      console.log(err);
      message.error(
        `Get errorr while creating new Magento Template: ${err.data.error}`
      );
    }
  };
  const handleMagentoModalCancel = () => {
    magentoform.resetFields();
    setMagentoModal(false);
  };
  const handleMagentoResultModalCancel = () => {
    setNewMagentoResultModal(false);
  };
  const handleMagentoUpgradeResultModalCancel = () => {
    setUpgradeMagentoResultModal(false);
  };
  const displayMagentoModal = async () => {
    try {
      setMagentoModal(true);
      setSpinning(true);
      const getTemplateSourceList = await axios.get(
        `https://multidomain.zonedweb.com/api/magento_source/list`
      );
      if (getTemplateSourceList.data.success === true) {
        setTemplateSourceList([...getTemplateSourceList.data.result]);
      } else {
        message.error(
          `cannot load template source list with Error: ${getTemplateSourceList.data.resource}`
        );
      }
    } catch (e) {
      console.log(e);
      message.error(`cannot load template source list with Error: ${e}`);
    }
  };

  const upgradeAllMagentoTemplate = async () => {
    const getMagentoLatestVersion = await axios.get(
      `https://multidomain.zonedweb.com/api/magento_version`
    );
    const magentoLatestVersion = getMagentoLatestVersion.data.result;
    setUpgradeMagentoResultModal(true);
    for (const template of tableData) {
      setUpgradeTable((current) => [
        ...current,
        {
          id: template.id,
          templateName: template.theme_name,
          result: "Waiting",
        },
      ]);
    }
    tableData.forEach(async (d, i) => {
      setUpgradeTable((current) =>
        current.map((obj) => {
          if (obj.id === d.id) {
            return { ...obj, result: `Upgrading` };
          }

          return obj;
        })
      );
      const upgradeTemplateResult = await axios.post(
        `https://multidomain.zonedweb.com/api/magento_upgrade`,
        { id: d.id, version: magentoLatestVersion },
        config
      );
      setUpgradeTable((current) =>
        current.map((obj) => {
          if (obj.id === d.id) {
            return {
              ...obj,
              result: upgradeTemplateResult.data.result,
              id: d.id,
            };
          }
          return obj;
        })
      );
    });
  };
  const confirmMassCliean = async (e) => {
    try {
      setTableLoad(true);
      for (let val of e) {
        const clearTemplateResult = await axios.post(
          `https://multidomain.zonedweb.com/api/magento/clean`,
          { id: val },
          config
        );
      }
      setSelectedDomain([]);

      const fetchTableData = await axios.get(
        "https://multidomain.zonedweb.com/api/magento",
        config
      );

      if (fetchTableData.data.success === true) {
        setTableData([...fetchTableData.data.result]);
        setTableLoad(false);
      }
    } catch (err) {
      console.log(err);
      message.error(`got error deleting template: ${err}`);
    }
  };
  useEffect(() => {
    const kickStart = async () => {
      try {
        const fetchTableData = await axios.get(
          "https://multidomain.zonedweb.com/api/magento",
          config
        );
        console.log(fetchTableData);
        if (fetchTableData.data.success === true) {
          setTableData([...fetchTableData.data.result]);
          setTableLoad(false);
        }
      } catch (err) {
        console.log(err);

        message.error(
          `got error while fetching domain list with Error: ${err}`
        );
      }
    };
    kickStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Modal
        title='Upgrade Template Result'
        visible={upgradeMagentoResultModal}
        // onOk={handleOk}
        onCancel={handleMagentoUpgradeResultModalCancel}
        footer={[]}
        width={800}
        bodyStyle={{ maxHeight: "500px", overflow: "scroll" }}
      >
        <Table
          rowKey='id'
          dataSource={upgradeTable}
          columns={upgradeColumns}
          pagination={{ pageSize: 500, position: ["none", "none"] }}
        />
      </Modal>
      <Modal
        title='Create New Template Result'
        visible={newMagentoResultModal}
        // onOk={handleOk}
        onCancel={handleMagentoResultModalCancel}
        footer={[]}
        width={800}
        bodyStyle={{ maxHeight: "500px", overflow: "scroll" }}
      >
        <Spin spinning={spinning} size='large'>
          <Row>
            <Col xs={6} style={{ textAlign: "right", padding: "8px" }}>
              Result:
            </Col>
            <Col xs={18} style={{ fontWeight: "bold", padding: "8px" }}>
              {resultResonse.success}
            </Col>
          </Row>
          <Row>
            <Col xs={6} style={{ textAlign: "right", padding: "8px" }}>
              Magento Link:
            </Col>
            <Col xs={18} style={{ fontWeight: "bold", padding: "8px" }}>
              <a href={resultResonse.magentoLink} target='_blank'>
                {resultResonse.magentoLink}{" "}
              </a>
            </Col>
          </Row>
          <Row>
            <Col xs={6} style={{ textAlign: "right", padding: "8px" }}>
              Magento Admin Link:
            </Col>
            <Col xs={18} style={{ fontWeight: "bold", padding: "8px" }}>
              <a target='_blank' href={resultResonse.adminLink}>
                {resultResonse.adminLink}
              </a>
            </Col>
          </Row>
          <Row>
            <Col xs={6} style={{ textAlign: "right", padding: "8px" }}>
              Magento Admin Username::
            </Col>
            <Col
              xs={18}
              style={{
                fontWeight: "bold",
                padding: "8px",
                color: "#cf1322",
              }}
            >
              grabshelf35556
            </Col>
          </Row>
          <Row>
            <Col xs={6} style={{ textAlign: "right", padding: "8px" }}>
              Magento Admin Password::
            </Col>
            <Col
              xs={18}
              style={{
                fontWeight: "bold",
                padding: "8px",
                color: "#cf1322",
              }}
            >
              P@$$w0rd35556
            </Col>
          </Row>
          <Row style={{ marginTop: "8px" }}>
            <Col xs={24} style={{ textAlign: "center" }}>
              <a target='_blank' href={resultResonse.adminLink}>
                <Button type='primary' shape='round' size='large' danger>
                  Go To Admin
                </Button>
              </a>
              {/* <form
                action={
                  resultResonse.magentoLink + '/index.php/grabshelfadmin/admin/'
                }
                method='post'
                target='_blank'
              >
                <input
                  type='hidden'
                  name='login[username]'
                  value='grabshelf35556'
                />
                <input
                  type='hidden'
                  name='login[password]'
                  value='P@$$w0rd35556'
                />
                
              </form> */}
            </Col>
          </Row>
        </Spin>
      </Modal>
      <Modal
        title='Create New Magento Template'
        visible={magentoModal}
        // onOk={handleOk}
        onCancel={handleMagentoModalCancel}
        footer={[]}
        width={600}
        bodyStyle={{ maxHeight: "500px", overflow: "scroll" }}
      >
        <Form
          name='install_new_magento'
          labelCol={{ span: 14 }}
          wrapperCol={{ span: 10 }}
          onFinish={onFinish}
          autoComplete='off'
          form={magentoform}
        >
          <Form.Item
            label='Choice Template source'
            name='template_source'
            rules={[
              {
                required: true,
                message: "Please select template source!",
              },
            ]}
          >
            <Select>
              {templateSourceList.map((source) => (
                <Option key={source.name}>{source.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label='Sub Directory (ex: carparts01)'
            name='domain'
            rules={[
              {
                required: true,
                message: "Please input new magento domain!",
              },
            ]}
          >
            <Input type='text' />
          </Form.Item>
          <Form.Item
            label='Theme Name (ex: Armania Car Parts 01)'
            name='themename'
            rules={[
              {
                required: true,
                message: "Please input new magento Theme Name!",
              },
            ]}
          >
            <Input type='text' />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 10, span: 14 }}>
            <Button type='primary' htmlType='submit' shape='round' size='large'>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Row justify='end' style={{ padding: "10px" }}>
        {selectedDomain.selectedDomain &&
          selectedDomain.selectedDomain.length > 0 && (
            <Col xs={10} sm={8} md={6} lg={13} xl={15}>
              <Popconfirm
                title={`Confirm Clean ${
                  selectedDomain.selectedDomain
                    ? selectedDomain.selectedDomain.length
                    : 0
                } domain ?`}
                onConfirm={() =>
                  confirmMassCliean(selectedDomain.selectedDomain)
                }
                onCancel={massCancel}
                okText='Yes'
                cancelText='No'
              >
                <Button
                  type='primary'
                  shape='round'
                  icon={<CheckCircleOutlined />}
                  size='middle'
                  className='product-head-action-button'
                  disabled={!hasSelected}
                >
                  Clean Template
                </Button>
              </Popconfirm>
              <Popconfirm
                title={`Confirm Delete ${
                  selectedDomain.selectedDomain
                    ? selectedDomain.selectedDomain.length
                    : 0
                } domain ?`}
                onConfirm={() =>
                  confirmMassDelete(selectedDomain.selectedDomain)
                }
                onCancel={massCancel}
                okText='Yes'
                cancelText='No'
              >
                <Button
                  type='primary'
                  shape='round'
                  icon={<CheckCircleOutlined />}
                  size='middle'
                  className='product-head-action-button'
                  disabled={!hasSelected}
                >
                  Delete Template
                </Button>
              </Popconfirm>

              <span
                style={{ marginLeft: 8, color: "#c2136b", fontWeight: 600 }}
              >
                {hasSelected
                  ? `Selected ${selectedDomain.selectedDomain.length} template`
                  : ""}
              </span>
            </Col>
          )}
        {/* <Col xs={2}>
          <form
            action='https://carparts.grabshelfvendors.com/index.php/grabshelfadmin/admin/'
            method='post'
            target='_blank'
          >
            <input
              type='hidden'
              name='login[username]'
              value='grabshelf35556'
            />
            <input type='hidden' name='login[password]' value='P@$$w0rd35556' />

            <input type='submit' value='Go to page' />
          </form>
        </Col> */}
        <Col xs={12} sm={8} md={6} lg={4} xl={3}>
          <Button type='primary' onClick={() => upgradeAllMagentoTemplate()}>
            Upgrade all template
          </Button>
        </Col>

        <Col xs={12} sm={8} md={6} lg={4} xl={3}>
          <Button type='primary' onClick={() => displayMagentoModal()}>
            Create New Template
          </Button>
        </Col>
        <Col
          xs={14}
          sm={16}
          md={12}
          lg={7}
          xl={6}
          style={{ paddingTop: "8px" }}
        >
          <Search
            placeholder='Search Template'
            enterButton
            name='searchProduct'
            onSearch={(e) => {
              handleDomainSearch(e);
            }}
          />
        </Col>
      </Row>
      <Table
        rowSelection={domainSelection}
        onRow={(record) => ({
          onClick: () => {
            adminlogin(record);
          },
        })}
        rowKey='id'
        dataSource={tableData}
        columns={columns}
        loading={tableLoading}
        pagination={{
          defaultPageSize: 30,
          total: tableData.length,
          showSizeChanger: false,
          position: ["bottomCenter"],

          size: "large",
          showQuickJumper: true,
        }}
        rowClassName={(record, index) => {
          return index % 2 === 0
            ? "table-row-light order-list-table-row"
            : "table-row-dark order-list-table-row";
        }}
      />
    </>
  );
}

export default MagentoTemplate;
