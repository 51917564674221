import React, { useState, useEffect } from "react";
import axios from "axios";
import { CheckCircleOutlined } from "@ant-design/icons";
import {
  message,
  Table,
  Input,
  Popconfirm,
  Form,
  Row,
  Col,
  Button,
  Modal,
  Spin,
  Select,
  Space,
} from "antd";
function MagentoSource() {
  const { Search } = Input;
  const { Option } = Select;
  const [magentoform] = Form.useForm();
  const [tableData, setTableData] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState([]);
  const [tableLoading, setTableLoad] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [magentoModal, setMagentoModal] = useState(false);
  const [newMagentoResultModal, setNewMagentoResultModal] = useState(false);
  const [spinning, setSpinning] = useState(true);
  const [hasSelected, setHasSelected] = useState(false);
  const confirmMassCliean = () => {};
  const massCancel = () => {};

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const columns = [
    {
      title: "id",
      dataIndex: "id",
      width: "5%",

      render: (text) => <center>{text}</center>,
    },
    {
      title: "Source Template Name",
      dataIndex: "name",
      width: "15%",
    },
    {
      title: "Source Template Dir",
      dataIndex: "dir",
      width: "40%",
    },
    {
      title: "Magento Version",
      dataIndex: "magento_version",
      width: "12%",
    },
    {
      title: "PHP Version",
      dataIndex: "php_version",
      width: "10%",

      render: (text) => <center>{text}</center>,
    },

    {
      title: "Action",
      key: "action",
      width: "15%",
      align: "center",
      render: (text, record) => (
        <Space size='middle'>
          <Popconfirm
            title='Sure to upgrade?'
            onConfirm={() => handleUpgrade(record.id)}
          >
            <a>Upgrade</a>
          </Popconfirm>
          <Popconfirm
            title='Sure to delete?'
            onConfirm={() => handleDelete(record.id)}
          >
            <a>Delete</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const handleDelete = async (val) => {
    try {
      setTableLoad(true);
      const deleteTemplateResult = await axios.delete(
        `https://multidomain.zonedweb.com/api/magento_source?id=${val}`,
        config
      );
      if (deleteTemplateResult.data.success === true) {
        const fetchTableData = await axios.get(
          "https://multidomain.zonedweb.com/api/magento_source",
          config
        );

        if (fetchTableData.data.success === true) {
          setTableData([...fetchTableData.data.result]);
          setTableLoad(false);
        }
      } else {
        message.error(
          `cannot delete template sourcve with error: ${deleteTemplateResult.data.result}`
        );
      }
    } catch (err) {
      console.log(err);
      message.error(`got error while delete template source: ${err}`);
    }
  };
  const handleUpgrade = async (val) => {
    try {
      setTableLoad(true);
      const getMagentoLatestVersion = await axios.get(
        `https://multidomain.zonedweb.com/api/magento_version`
      );
      const magentoLatestVersion = getMagentoLatestVersion.data.result;

      const upgradeTemplateResult = await axios.post(
        `https://multidomain.zonedweb.com/api/magento_source_upgrade`,
        { id: val, version: magentoLatestVersion },
        config
      );
      if (upgradeTemplateResult.data.success === true) {
        const fetchTableData = await axios.get(
          "https://multidomain.zonedweb.com/api/magento_source",
          config
        );
        if (
          upgradeTemplateResult.data.result ===
          "Template already latest version"
        ) {
          message.success(
            `Template's already running latest version, no need to upgrade`
          );
        }
        if (fetchTableData.data.success === true) {
          setTableData([...fetchTableData.data.result]);
          setTableLoad(false);
        }
      } else {
        message.error(
          `cannot upgrade template sourcve with error: ${upgradeTemplateResult.data.result}`
        );
      }
    } catch (err) {
      console.log(err);
      message.error(`got error while delete template source: ${err}`);
    }
  };
  const confirmMassDelete = async (e) => {
    try {
      setTableLoad(true);
      for (let val of e) {
        const deleteTemplateREsult = await axios.delete(
          `https://multidomain.zonedweb.com/api/magento_source?id=${val}`,
          config
        );
      }
      setSelectedDomain([]);
      setSelectedDomain([]);

      const fetchTableData = await axios.get(
        "https://multidomain.zonedweb.com/api/magento_source",
        config
      );

      if (fetchTableData.data.success === true) {
        setTableData([...fetchTableData.data.result]);
        setTableLoad(false);
      }
    } catch (err) {
      console.log(err);
      message.error(`got error deleting template source: ${err}`);
    }
  };
  const onSelectChange = (selectedDomain) => {
    setSelectedDomain({ selectedDomain });
    setHasSelected(selectedDomain.length > 0);
  };

  const handleDomainSearch = async (value) => {
    try {
      setTableLoad(true);
      if (value.length === 0) {
        const fetchTableData = await axios.get(
          "https://multidomain.zonedweb.com/api/magento_source",
          config
        );
        console.log(fetchTableData);
        if (fetchTableData.data.success === true) {
          setTableData([...fetchTableData.data.result]);
          setTableLoad(false);
        }
      }
      if (value.length > 0) {
        const fetchTableData = await axios.get(
          `https://multidomain.zonedweb.com/api/magento_source?search=${value}`,
          config
        );
        console.log(fetchTableData);
        if (fetchTableData.data.success === true) {
          setTableData([...fetchTableData.data.result]);
          setTableLoad(false);
        }
      }
    } catch (err) {
      console.log(err);

      message.error(
        `got error while fetching template source list with Error: ${err}`
      );
    }
  };
  const domainSelection = {
    selectedDomain,
    onChange: onSelectChange,
  };
  const handleMagentoModalCancel = () => {
    setMagentoModal(false);
  };
  const displayMagentoModal = () => {
    setMagentoModal(true);
    setSpinning(true);
  };

  const onFinish = async (e) => {
    try {
      const createMagentoSourceResult = await axios.post(
        `https://multidomain.zonedweb.com/api/magento_source`,
        { e },
        config
      );
      if (createMagentoSourceResult.data.success === true) {
        setMagentoModal(false);
        setSpinning(false);
        setTableLoad(true);
        const fetchTableData = await axios.get(
          "https://multidomain.zonedweb.com/api/magento_source",
          config
        );

        if (fetchTableData.data.success === true) {
          setTableData([...fetchTableData.data.result]);
          setTableLoad(false);
        }
      } else {
        setNewMagentoResultModal(false);
        message.error(
          `Get errorr while creating new Magento Template Source: ${createMagentoSourceResult.data.result}`
        );

        magentoform.resetFields();
      }
    } catch (err) {
      console.log(err);
      message.error(
        `Get errorr while creating new Magento Template: ${err.data.error}`
      );
    }
  };
  useEffect(() => {
    const kickStart = async () => {
      try {
        const fetchTableData = await axios.get(
          "https://multidomain.zonedweb.com/api/magento_source",
          config
        );
        console.log(fetchTableData);
        if (fetchTableData.data.success === true) {
          setTableData([...fetchTableData.data.result]);
          setTableLoad(false);
        }
      } catch (err) {
        console.log(err);

        message.error(
          `got error while fetching domain list with Error: ${err}`
        );
      }
    };
    kickStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal
        title='Create New Magento Source'
        visible={magentoModal}
        // onOk={handleOk}
        onCancel={handleMagentoModalCancel}
        footer={[]}
        width={600}
        bodyStyle={{ maxHeight: "500px", overflow: "scroll" }}
      >
        <Form
          name='install_new_magento_source'
          labelCol={{ span: 9 }}
          wrapperCol={{ span: 15 }}
          onFinish={onFinish}
          autoComplete='off'
          form={magentoform}
        >
          <Form.Item
            label='New template source name'
            name='name'
            rules={[
              {
                required: true,
                message: "Please input new magento template source name!",
              },
            ]}
          >
            <Input type='text' />
          </Form.Item>
          <Form.Item
            label='New template source dir'
            name='dir'
            rules={[
              {
                required: true,
                message:
                  "Please input new magento template source dir (/home/xxx/public_html)!",
              },
            ]}
          >
            <Input type='text' />
          </Form.Item>
          <Form.Item
            name='phpVersion'
            label='PHP Version'
            rules={[{ required: true }]}
          >
            <Select
              placeholder='Select a new template source required PHP Version'
              allowClear
            >
              <Option value='7.4'>7.4</Option>
              <Option value='8.0'>8.0</Option>
              <Option value='8.1'>8.1</Option>
            </Select>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 10, span: 14 }}>
            <Button type='primary' htmlType='submit' shape='round' size='large'>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Row justify='end' style={{ padding: "10px" }}>
        {selectedDomain.selectedDomain &&
          selectedDomain.selectedDomain.length > 0 && (
            <Col xs={8} sm={7} md={8} lg={9} xl={10}>
              <Popconfirm
                title={`Confirm Delete ${
                  selectedDomain.selectedDomain
                    ? selectedDomain.selectedDomain.length
                    : 0
                } domain ?`}
                onConfirm={() =>
                  confirmMassDelete(selectedDomain.selectedDomain)
                }
                onCancel={massCancel}
                okText='Yes'
                cancelText='No'
              >
                <Button
                  type='primary'
                  shape='round'
                  icon={<CheckCircleOutlined />}
                  size='middle'
                  className='product-head-action-button'
                  disabled={!hasSelected}
                >
                  Delete Template Source
                </Button>
              </Popconfirm>

              <span
                style={{ marginLeft: 8, color: "#c2136b", fontWeight: 600 }}
              >
                {hasSelected
                  ? `Selected ${selectedDomain.selectedDomain.length} template Source`
                  : ""}
              </span>
            </Col>
          )}
        {/* <Col xs={2}>
      <form
        action='https://carparts.grabshelfvendors.com/index.php/grabshelfadmin/admin/'
        method='post'
        target='_blank'
      >
        <input
          type='hidden'
          name='login[username]'
          value='grabshelf35556'
        />
        <input type='hidden' name='login[password]' value='P@$$w0rd35556' />

        <input type='submit' value='Go to page' />
      </form>
    </Col> */}
        <Col xs={16} sm={8} md={8} lg={8} xl={8}>
          <Button type='primary' onClick={() => displayMagentoModal()}>
            Create New Template Source
          </Button>
        </Col>

        <Col xs={12} sm={9} md={8} lg={7} xl={6}>
          <Search
            placeholder='Search Template'
            enterButton
            name='searchProduct'
            onSearch={(e) => {
              handleDomainSearch(e);
            }}
          />
        </Col>
      </Row>
      <Table
        rowSelection={domainSelection}
        rowKey='id'
        dataSource={tableData}
        columns={columns}
        loading={tableLoading}
        pagination={{
          defaultPageSize: 30,
          total: tableData.length,
          showSizeChanger: false,
          position: ["bottomCenter"],

          size: "large",
          showQuickJumper: true,
        }}
        rowClassName={(record, index) => {
          return index % 2 === 0
            ? "table-row-light order-list-table-row"
            : "table-row-dark order-list-table-row";
        }}
      />
    </>
  );
}

export default MagentoSource;
