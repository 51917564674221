import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  Layout,
  Modal,
  Form,
  Input,
  Button,
  Select,
  Spin,
  Row,
  Col,
  message,
} from "antd";
import "antd/dist/antd.css";
import Sidebar from "./components/layout/Sidebar";
import Navbar from "./components/layout/Navbar";
import Login from "./components/auth/Login";
// import Register from './components/auth/Register';
import Main from "./components/Main";
import setAuthToken from "./components/util/setAuthToken";
import MyRoute from "./components/routing/MyRoute";
import Domain from "./components/Domain";
import Zones from "./components/Zones";
import Cpanels from "./components/Cpanels";
import EmailPlans from "./components/emailPlans";
import EmailDomains from "./components/emailDomains";
import VideoLinks from "./components/videoLinks";
import MagentoTemplate from "./components/magento";
import MagentoSource from "./components/magentoSource";
import Settings from "./components/Settings";
import axios from "axios";

import "./App.css";
// import Manage from './components/Manage';

function App() {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const { Option } = Select;

  const { Content, Footer } = Layout,
    [collapsed, setCollapsed] = useState(undefined),
    [isAuthenticated, setIsAuthenticated] = useState(false),
    [magentoform] = Form.useForm(),
    bugerClick = () => {
      setCollapsed(!collapsed);
    },
    onCollapse = (collapsed) => {
      setCollapsed(collapsed);
    };
  const setAuthen = (data) => {
    setIsAuthenticated(data);
  };
  // const displayMagentoModal = () => {
  //   setMagentoModal(true);
  //   setSpinning(true);
  // };
  // const handleMagentoModalCancel = () => {
  //   setMagentoModal(false);
  // };
  // const handleMagentoResultModalCancel = () => {
  //   setNewMagentoResultModal(false);
  // };

  // const onFinish = async (e) => {
  //   try {
  //     setMagentoModal(false);
  //     setNewMagentoResultModal(true);
  //     const createMagentoResult = await axios.post(
  //       `https://multidomain.zonedweb.com/api/magento`,
  //       { domain: `${e.domain}.grabshelfvendors.com` },
  //       config
  //     );

  //     if (createMagentoResult.data.success === true) {
  //       setResultResponse({
  //         success: 'success',
  //         cpanelUser: createMagentoResult.data.cpanelUser,
  //         cpanelPassword: createMagentoResult.data.cpanelPassword,
  //         adminLink: createMagentoResult.data.adminLink,
  //         adminUsername: createMagentoResult.data.adminUsername,
  //         adminPassword: createMagentoResult.data.adminPassword,
  //         magentoLink: `https://${e.domain}.grabshelfvendors.com`,
  //       });
  //       setSpinning(false);
  //     } else {
  //       setResultResponse({
  //         success: false,
  //         cpanelUser: 'pendding',
  //         cpanelPassword: 'pendding',
  //         adminLink: 'pendding',
  //         adminUsername: 'pendding',
  //         adminPassword: 'pendding',
  //         magentoLink: 'pending',
  //       });
  //       setNewMagentoResultModal(false);
  //       message.error(
  //         `Get errorr while creating new Magento Template: Domain already exists`
  //       );
  //     }

  //     magentoform.resetFields();
  //   } catch (err) {
  //     console.log(err);
  //     message.error(
  //       `Get errorr while creating new Magento Template: ${err.data.error}`
  //     );
  //   }
  // };
  return (
    <Router>
      <Layout style={{ minHeight: "100vh" }}>
        <Sidebar collapsed={collapsed} onCollapse={onCollapse} />
        <Layout className='site-layout'>
          <Navbar
            bugerClick={bugerClick}
            isAuthenticated={isAuthenticated}
            setAuthen={setAuthen}
            // displayMagentoModal={displayMagentoModal}
          />
          <Content style={{ margin: "5px" }}>
            <Switch>
              <Route exact path='/manage' component={Main} />
              <Route
                exact
                path='/manage/login'
                component={() => (
                  <Login
                    isAuthenticated={isAuthenticated}
                    setAuthen={setAuthen}
                  />
                )}
              />
              {/* <Route
                exact
                path='/manage/register'
                component={() => (
                  <Register
                    isAuthenticated={isAuthenticated}
                    setAuthen={setAuthen}
                  />
                )}
              /> */}
              <MyRoute exact path='/manage/domains' component={Domain} />
              <MyRoute exact path='/manage/zones' component={Zones} />
              <MyRoute exact path='/manage/cpanels' component={Cpanels} />
              <MyRoute exact path='/manage/emailplans' component={EmailPlans} />
              <MyRoute
                exact
                path='/manage/emaildomains'
                component={EmailDomains}
              />
              <MyRoute exact path='/manage/videolinks' component={VideoLinks} />
              <MyRoute
                exact
                path='/manage/magento_template'
                component={MagentoTemplate}
              />
              <MyRoute
                exact
                path='/manage/magento_source'
                component={MagentoSource}
              />
              <MyRoute exact path='/manage/settings' component={Settings} />
            </Switch>
          </Content>
          <Footer style={{ textAlign: "center" }}>
            Grabshelf ©2021 Created by James
          </Footer>
        </Layout>
      </Layout>
    </Router>
  );
}

export default App;
