import React, { useState, useEffect } from "react";
// import { isMobile } from 'react-device-detect';
import axios from "axios";
import {
  message,
  Table,
  Input,
  Row,
  Col,
  Popconfirm,
  Button,
  Form,
  Modal,
  Space,
} from "antd";
import {
  CheckCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
const VideoLinks = () => {
  const { Search } = Input;
  const [tableData, setTableData] = useState([]),
    [tableLoading, setTableLoad] = useState(true),
    [selectedDomain, setSelectedDomain] = useState([]),
    [isModalVisible, setIsModalVisible] = useState(false),
    [videoLinkArray, setVideoLinkArray] = useState([]),
    [videoDescArray, setVideoDescArray] = useState([]),
    [isEditModalVisible, setIsEditModalVisible] = useState(false),
    [hasSelected, setHasSelected] = useState(false);
  const [form] = Form.useForm();
  const [editform] = Form.useForm();
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const columns = [
    {
      title: "id",
      dataIndex: "id",
      width: "3%",

      render: (text) => <center>{text}</center>,
    },
    {
      title: "Themes Group Name",
      dataIndex: "themes_group",
      width: "15%",
      align: "left",
    },
    {
      title: "Video Links",
      dataIndex: "video_url",
      width: "81%",
      align: "center",
      ellipsis: true,
    },
  ];
  const formItemLayout = {
    labelCol: {
      xs: { span: 8 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 15 },
      sm: { span: 15 },
    },
  };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 15, offset: 8 },
      sm: { span: 15, offset: 8 },
    },
  };
  const onSelectChange = (selectedDomain) => {
      setSelectedDomain({ selectedDomain });
      setHasSelected(selectedDomain.length > 0);
    },
    domainSelection = {
      selectedDomain,
      onChange: onSelectChange,
    };
  const handleThemesSearch = async (value) => {
    try {
      setTableLoad(true);
      if (value.length === 0) {
        const fetchTableData = await axios.get(
          "https://multidomain.zonedweb.com/api/video",
          config
        );
        console.log(fetchTableData);
        if (fetchTableData.data.success === true) {
          setTableData([...fetchTableData.data.result]);
          setTableLoad(false);
        }
      }
      if (value.length > 0) {
        const fetchTableData = await axios.get(
          `https://multidomain.zonedweb.com/api/video/${value}`,
          config
        );
        console.log(fetchTableData);
        if (fetchTableData.data.success === true) {
          setTableData([...fetchTableData.data.result]);
          setTableLoad(false);
        }
      }
    } catch (err) {
      console.log(err);

      message.error(
        `got error while fetching themes video list with Error: ${err}`
      );
    }
  };
  const showEditModal = (e) => {
    console.log(e);
    setVideoLinkArray([...e.videoArr]);
    setVideoDescArray([...e.videoDescArr]);
    editform.setFieldsValue({ ...e });
    setIsEditModalVisible(true);
  };
  const handleEditCancel = (e) => {
    setIsEditModalVisible(false);
  };
  const onEditFinish = async (e) => {
    try {
      // const dataSubmit = { ...e };
      console.log(e);
      const video = [];
      const description = [];
      for (let val in e) {
        if (val.match(/^video/g)) {
          video.push(e[val]);
        }
        if (val.match(/^desc/g)) {
          description.push(e[val]);
        }
      }
      const saveVideoResult = await axios.put(
        `https://multidomain.zonedweb.com/api/video`,
        {
          id: e.id,
          themes: e.themes_group,
          video: video,
          description: description,
        }
      );
      if (saveVideoResult.data.success === true) {
        setTableLoad(true);
        const fetchTableData = await axios.get(
          "https://multidomain.zonedweb.com/api/video",
          config
        );

        if (fetchTableData.data.success === true) {
          setTableData([...fetchTableData.data.result]);
          setTableLoad(false);
        } else {
          message.error(
            `got error while saving video with error: ${fetchTableData.data.result}`
          );
        }
      } else {
        message.error(
          `got error while saving video with error: ${saveVideoResult.data.result}`
        );
      }
      setIsEditModalVisible(false);
    } catch (err) {
      message.error(`got error while loading table data: ${err}`);
    }
  };
  const handleCreateVideoModal = () => {
      setIsModalVisible(true);
    },
    massCancel = (e) => {
      message.success("Canceled themes video delete");
    };
  const confirmMassDelete = async (e) => {
    try {
      for (let val of e) {
        const addDomainResult = await axios.delete(
          "https://multidomain.zonedweb.com/api/video",
          {
            data: { id: val },
            ...config,
          }
        );
        if (addDomainResult.data.success === true) {
          setTableData((prev) => {
            const result = prev.filter((v) => v.id !== val);
            return [...result];
          });
        }
      }
      setSelectedDomain([]);
    } catch (err) {
      console.log(err);
      message.error(`got error deleting themes video: ${err}`);
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const cancleHandler = (e) => {
    setIsEditModalVisible(false);
  };
  const deleteVideo = (item, index) => {
    const tempArr = videoLinkArray.filter((value) => {
      return value != item;
    });

    setVideoLinkArray([...tempArr]);
  };
  const deleteVideoDesc = (item, index) => {
    const tempArr = videoDescArray.filter((value) => {
      return value != item;
    });

    setVideoDescArray([...tempArr]);
  };
  useEffect(() => {
    const kickStart = async () => {
      try {
        const fetchTableData = await axios.get(
          "https://multidomain.zonedweb.com/api/video",
          config
        );
        console.log(fetchTableData);
        if (fetchTableData.data.success === true) {
          setTableData([...fetchTableData.data.result]);
          setTableLoad(false);
        }
      } catch (err) {
        console.log(err);

        message.error(
          `got error while fetching themes video list with Error: ${err}`
        );
      }
    };
    kickStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onFinish = async (e) => {
    try {
      e.video = [];
      e.description = [];
      for (let val of e.videoList) {
        e.video.push(val.video);
        e.description.push(val.description);
      }
      const saveVideoResult = await axios.post(
        `https://multidomain.zonedweb.com/api/video`,
        e,
        config
      );

      if (saveVideoResult.data.success === true) {
        setTableLoad(true);
        const fetchTableData = await axios.get(
          "https://multidomain.zonedweb.com/api/video",
          config
        );

        if (fetchTableData.data.success === true) {
          setTableData([...fetchTableData.data.result]);
          setTableLoad(false);
        } else {
          message.error(
            `got error while creating video link with error: ${fetchTableData.data.result}`
          );
        }
      } else {
        message.error(
          `got error while creating video link  with error: ${saveVideoResult.data.result}`
        );
      }
      setIsModalVisible(false);
    } catch (err) {
      message.error(`got error while loading table data: ${err}`);
    }
  };
  return (
    <>
      <Modal
        title='Edit Themes Video'
        visible={isEditModalVisible}
        onOk={handleOk}
        onCancel={handleEditCancel}
        footer={[]}
        width={800}
        bodyStyle={{ maxHeight: "500px", overflow: "scroll" }}
      >
        <Form
          name='editThemesVideo'
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          onFinish={onEditFinish}
          form={editform}
          autoComplete='off'
        >
          <Form.Item label='id' name='id' style={{ display: "none" }}>
            <Input />
          </Form.Item>
          <Form.Item
            label='Themes Group Name'
            name='themes_group'
            rules={[
              { required: true, message: "Please input Themes group name!" },
            ]}
          >
            <Input />
          </Form.Item>
          {videoLinkArray.map((item, index) => {
            const fielvalue = {};
            fielvalue[`video[${index}]`] = item;
            const fiel1value = {};
            fiel1value[`desc[${index}]`] = videoDescArray[index];
            editform.setFieldsValue(fielvalue);
            editform.setFieldsValue(fiel1value);
            return (
              <Row key={`video${index}row`} id={`video${index}row`}>
                <Col xs={22}>
                  <Form.Item
                    label={`Video Link #${index}`}
                    // name={`video${index}`}
                    name={`video[${index}]`}
                    key={`video${index}`}
                    className='abc'
                    rules={[
                      { required: true, message: "Please input video link!" },
                    ]}
                  >
                    <Input id={`video${index}`} name='video[]' />
                  </Form.Item>
                  <Form.Item
                    label={`Video Description #${index}`}
                    // name={`video${index}`}
                    name={`desc[${index}]`}
                    key={`videodesc${index}`}
                    rules={[
                      {
                        required: true,
                        message: "Please input video description!",
                      },
                    ]}
                  >
                    <Input id={`videodesc${index}`} name='video[]' />
                  </Form.Item>
                </Col>
                <Col xs={2} style={{ textAlign: "center" }}>
                  {videoLinkArray.length > 1 ? (
                    <Button
                      type='danger'
                      key={`delete${index}`}
                      // onClick={() => add()}
                      onClick={() => {
                        deleteVideo(item, index);
                        deleteVideoDesc(videoDescArray[index], index);
                      }}
                      icon={<MinusCircleOutlined />}
                    />
                  ) : null}
                </Col>
              </Row>
            );
          })}
          <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
            <Button
              type='dashed'
              onClick={() => setVideoLinkArray((prev) => [...prev, ""])}
              style={{ width: "60%" }}
              icon={<PlusOutlined />}
            >
              Add More Video
            </Button>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button
              type='primary'
              htmlType='submit'
              style={{ marginRight: "10px" }}
            >
              Save
            </Button>
            <Button type='danger' onClick={cancleHandler}>
              Cancal
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title='Add new Themes Video'
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
        bodyStyle={{ maxHeight: "500px", overflow: "scroll" }}
        width={800}
      >
        <Form
          name='addNewThemesVideo'
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 15 }}
          onFinish={onFinish}
          form={form}
          autoComplete='off'
        >
          <Form.Item
            label='Themes Group Name'
            name='themes'
            rules={[
              { required: true, message: "Please input themes group name!" },
            ]}
          >
            <Input />
          </Form.Item>
          {/* <Form.List
            name='video'
            rules={[
              {
                validator: async (_, names) => {
                  if (!names || names.length < 1) {
                    return Promise.reject(
                      new Error('At least 1 video link needed')
                    );
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    {...(index === 0
                      ? formItemLayout
                      : formItemLayoutWithOutLabel)}
                    label={index === 0 ? 'Video' : ''}
                    required={false}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message:
                            'Please input video link or delete this field.',
                        },
                      ]}
                      noStyle
                    >
                      <Input
                        placeholder='Video Link'
                        style={{ width: '85%' }}
                      />
                    </Form.Item>
                    {fields.length > 1 ? (
                      <Button
                        type='danger'
                        key={`delete${index}`}
                        // onClick={() => add()}
                        onClick={() => remove(field.name)}
                        icon={<MinusCircleOutlined />}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                  <Button
                    type='dashed'
                    onClick={() => add()}
                    style={{ width: '60%' }}
                    icon={<PlusOutlined />}
                  >
                    Add More Video
                  </Button>

                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List> */}
          <Form.List name='videoList'>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Row key={`row${key}`}>
                    <Col xs={22}>
                      <Form.Item
                        {...restField}
                        name={[name, "video"]}
                        fieldKey={[fieldKey, "video"]}
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        label={`video Link #${key}`}
                        style={{ marginBottom: "0 !important" }}
                        className='abc'
                        rules={[
                          { required: true, message: "Missing video link" },
                        ]}
                      >
                        <Input placeholder='Video Link' />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "description"]}
                        label={`video Description #${key}`}
                        fieldKey={[fieldKey, "description"]}
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        rules={[
                          {
                            required: true,
                            message: "Missing Video description",
                          },
                        ]}
                      >
                        <Input placeholder='Video Description' />
                      </Form.Item>
                    </Col>
                    <Col xs={2} style={{ textAlign: "center" }}>
                      <Button
                        type='danger'
                        key={`delete${key}`}
                        // onClick={() => add()}
                        onClick={() => remove(name)}
                        icon={<MinusCircleOutlined />}
                      />
                    </Col>
                  </Row>
                ))}
                <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
                  <Button
                    type='dashed'
                    onClick={() => add()}
                    style={{ width: "60%" }}
                    icon={<PlusOutlined />}
                  >
                    Add More Video
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button
              type='primary'
              htmlType='submit'
              style={{ marginRight: "8px" }}
            >
              Submit
            </Button>
            <Button
              type='danger'
              onClick={() => {
                setIsModalVisible(false);
              }}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Row justify='end' style={{ padding: "10px" }}>
        {selectedDomain.selectedDomain &&
          selectedDomain.selectedDomain.length > 0 && (
            <Col xs={10} sm={8} md={6} lg={13} xl={15}>
              <Popconfirm
                title={`Confirm Delete ${
                  selectedDomain.selectedDomain
                    ? selectedDomain.selectedDomain.length
                    : 0
                } video ?`}
                onConfirm={() =>
                  confirmMassDelete(selectedDomain.selectedDomain)
                }
                onCancel={massCancel}
                okText='Yes'
                cancelText='No'
              >
                <Button
                  type='primary'
                  shape='round'
                  icon={<CheckCircleOutlined />}
                  size='middle'
                  className='product-head-action-button'
                  disabled={!hasSelected}
                >
                  Delete Themes Video
                </Button>
              </Popconfirm>
              <span
                style={{ marginLeft: 8, color: "#c2136b", fontWeight: 600 }}
              >
                {hasSelected
                  ? `Selected ${selectedDomain.selectedDomain.length} Themes Video`
                  : ""}
              </span>
            </Col>
          )}

        <Col xs={10} sm={8} md={6} lg={4} xl={3}>
          <Button
            type='primary'
            onClick={() => handleCreateVideoModal({}, "new")}
          >
            Create New Themes Video
          </Button>
        </Col>
        <Col xs={14} sm={16} md={12} lg={7} xl={6}>
          <Search
            placeholder='Search Themes Video'
            enterButton
            name='searchThemes'
            onSearch={(e) => {
              handleThemesSearch(e);
            }}
          />
        </Col>
      </Row>
      <Table
        rowSelection={domainSelection}
        rowKey='id'
        dataSource={tableData}
        columns={columns}
        loading={tableLoading}
        pagination={{
          defaultPageSize: 30,
          total: tableData.length,
          showSizeChanger: false,
          position: ["bottomCenter"],
          hideOnSinglePage: true,
          size: "large",
          showQuickJumper: true,
        }}
        rowClassName={(record, index) => {
          return index % 2 === 0
            ? "table-row-light order-list-table-row"
            : "table-row-dark order-list-table-row";
        }}
        onRow={(record) => ({
          onClick: () => {
            showEditModal(record);
          },
        })}
      />
    </>
  );
};

export default VideoLinks;
