import React, { useState, useEffect } from "react";
// import { isMobile } from 'react-device-detect';
import axios from "axios";
import {
  message,
  Table,
  Input,
  Popconfirm,
  Form,
  Row,
  Col,
  Button,
  Modal,
} from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
function Domain() {
  const { Search } = Input;
  const [tableData, setTableData] = useState([]),
    [selectedDomain, setSelectedDomain] = useState([]),
    [tableLoading, setTableLoad] = useState(true),
    [isModalVisible, setIsModalVisible] = useState(false),
    [isEditModalVisible, setIsEditModalVisible] = useState(false),
    [hasSelected, setHasSelected] = useState(false);

  const [form] = Form.useForm();
  const [editform] = Form.useForm();

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const columns = [
      {
        title: "id",
        dataIndex: "id",
        width: "3%",
        editable: false,
        render: (text) => <center>{text}</center>,
      },
      {
        title: "domain",
        dataIndex: "domain",
        width: "13%",
        editable: true,
      },
      {
        title: "account ID",
        dataIndex: "accountid",
        width: "20%",
        editable: true,
        render: (text) => <center>{text}</center>,
      },
      {
        title: "Zone ID",
        dataIndex: "zoneid",
        width: "20%",
        editable: true,
        render: (text) => <center>{text}</center>,
      },
      {
        title: "CPANEL IP",
        dataIndex: "serverip",
        width: "10%",
        editable: true,
        render: (text) => <center>{text}</center>,
      },
      {
        title: "CPANEL ID",
        dataIndex: "serverid",
        width: "7%",
        editable: true,
        render: (text) => <center>{text}</center>,
      },
      {
        title: "Proxy external IP",
        dataIndex: "proxy_external",
        width: "13%",
        editable: true,
        render: (text) => <center>{text}</center>,
      },
      {
        title: "Proxy internal IP",
        dataIndex: "proxy_internal",
        width: "13%",
        editable: true,
        render: (text) => <center>{text}</center>,
      },
    ],
    onSelectChange = (selectedDomain) => {
      setSelectedDomain({ selectedDomain });
      setHasSelected(selectedDomain.length > 0);
    },
    domainSelection = {
      selectedDomain,
      onChange: onSelectChange,
    };
  const handleCreateDomainModal = () => {
      setIsModalVisible(true);
    },
    massCancel = (e) => {
      message.success("Canceled domain delete");
    };
  const handleDomainSearch = async (value) => {
    try {
      setTableLoad(true);
      if (value.length === 0) {
        const fetchTableData = await axios.get(
          "https://multidomain.zonedweb.com/api/cloudflare/domains",
          config
        );
        console.log(fetchTableData);
        if (fetchTableData.data.success === true) {
          setTableData([...fetchTableData.data.result]);
          setTableLoad(false);
        }
      }
      if (value.length > 0) {
        const fetchTableData = await axios.get(
          `https://multidomain.zonedweb.com/api/cloudflare/domains?search=${value}`,
          config
        );
        console.log(fetchTableData);
        if (fetchTableData.data.success === true) {
          setTableData([...fetchTableData.data.result]);
          setTableLoad(false);
        }
      }
    } catch (err) {
      console.log(err);

      message.error(`got error while fetching domain list with Error: ${err}`);
    }
  };
  const confirmMassDelete = async (e) => {
    try {
      for (let val of e) {
        const addDomainResult = await axios.delete(
          "https://multidomain.zonedweb.com/api/cloudflare/domains",
          {
            data: { id: val },
            ...config,
          }
        );
        if (addDomainResult.data.success === true) {
          setTableData((prev) => {
            const result = prev.filter((v) => v.id !== val);
            return [...result];
          });
        }
      }
      setSelectedDomain([]);
    } catch (err) {
      console.log(err);
      message.error(`got error adding domain: ${err}`);
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleEditOk = () => {
    setIsEditModalVisible(false);
  };

  const handleEditCancel = () => {
    setIsEditModalVisible(false);
  };
  const onEditFinish = async (e) => {
    try {
      const body = JSON.stringify(e);
      const editDomainResult = await axios.put(
        "https://multidomain.zonedweb.com/api/cloudflare/domains",
        body,
        config
      );
      if (
        !editDomainResult ||
        !editDomainResult.data ||
        editDomainResult.data.success !== true
      ) {
        message.error(editDomainResult.data.result);
      }
      if (
        editDomainResult &&
        editDomainResult.data &&
        editDomainResult.data.success === true
      ) {
        editform.resetFields();
        setIsEditModalVisible(false);
        setTableLoad(true);
        const fetchTableData = await axios.get(
          "https://multidomain.zonedweb.com/api/cloudflare/domains",
          config
        );
        setTableData([...fetchTableData.data.result]);
        setTableLoad(false);
      }
    } catch (err) {
      console.log(err);

      message.error(`got error adding domain: ${err}`);
    }
  };
  const onFinish = async (e) => {
    try {
      const body = JSON.stringify(e);
      const addDomainResult = await axios.post(
        "https://multidomain.zonedweb.com/api/cloudflare/domains",
        body,
        config
      );
      if (
        !addDomainResult ||
        !addDomainResult.data ||
        addDomainResult.data.success !== true
      ) {
        message.error(addDomainResult.data.result);
      }
      if (
        addDomainResult &&
        addDomainResult.data &&
        addDomainResult.data.success === true
      ) {
        setTableData((prev) => [
          {
            id: addDomainResult.data.result,
            domain: e.domain,
            accountid: e.accountid,
            zoneid: e.zoneid,
            serverip: e.serverip,
            serverid: addDomainResult.data.serverid,
          },
          ...prev,
        ]);
        form.resetFields();
        setIsModalVisible(false);
      }
    } catch (err) {
      console.log(err);

      message.error(`got error adding domain: ${err}`);
    }
  };
  const domainClicked = async (e) => {
    editform.setFieldsValue({ ...e });
    setIsEditModalVisible(true);
  };
  useEffect(() => {
    const kickStart = async () => {
      try {
        const fetchTableData = await axios.get(
          "https://multidomain.zonedweb.com/api/cloudflare/domains",
          config
        );
        console.log(fetchTableData);
        if (fetchTableData.data.success === true) {
          setTableData([...fetchTableData.data.result]);
          setTableLoad(false);
        }
      } catch (err) {
        console.log(err);

        message.error(
          `got error while fetching domain list with Error: ${err}`
        );
      }
    };
    kickStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Modal
        title='Add new domain'
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form
          name='addDomain'
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
          form={form}
          autoComplete='off'
        >
          <Form.Item
            label='Domain'
            name='domain'
            rules={[{ required: true, message: "Please input Domain name!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label='account ID'
            name='accountid'
            rules={[
              { required: true, message: "Please input domain account ID!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Zone ID'
            name='zoneid'
            rules={[
              { required: true, message: "Please input domain Zone Id!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Server IP Address'
            name='serverip'
            rules={[
              {
                required: true,
                message:
                  "Please input the IP of the Cpanel server that this domain will install!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title='Edit domain'
        visible={isEditModalVisible}
        onOk={handleEditOk}
        onCancel={handleEditCancel}
        footer={[]}
      >
        <Form
          name='editDomain'
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 14 }}
          onFinish={onEditFinish}
          form={editform}
          autoComplete='off'
        >
          <Form.Item hidden={true} name='id'>
            <Input type='hidden' />
          </Form.Item>
          <Form.Item
            label='Domain'
            name='domain'
            rules={[{ required: true, message: "Please input Domain name!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label='account ID'
            name='accountid'
            rules={[
              { required: true, message: "Please input domain account ID!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Zone ID'
            name='zoneid'
            rules={[
              { required: true, message: "Please input domain Zone Id!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Server IP Address'
            name='serverip'
            rules={[
              {
                required: true,
                message:
                  "Please input the IP of the Cpanel server that this domain will install!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Loadbalacner External IP'
            name='proxy_external'
            rules={[
              {
                required: true,
                message:
                  "Please input the External IP of the nginx loadbalancer that this domain will install!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Loadbalacner Internal IP'
            name='proxy_internal'
            rules={[
              {
                required: true,
                message:
                  "Please input the Internal IP of the nginx loadbalancer that this domain will install!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 14 }}>
            <Button type='primary' htmlType='submit'>
              Save
            </Button>
            <Button
              type='primary'
              danger
              onClick={() => handleEditCancel()}
              style={{ marginLeft: "16px" }}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Row justify='end' style={{ padding: "10px" }}>
        {selectedDomain.selectedDomain &&
          selectedDomain.selectedDomain.length > 0 && (
            <Col xs={10} sm={8} md={6} lg={13} xl={15}>
              <Popconfirm
                title={`Confirm Delete ${
                  selectedDomain.selectedDomain
                    ? selectedDomain.selectedDomain.length
                    : 0
                } domain ?`}
                onConfirm={() =>
                  confirmMassDelete(selectedDomain.selectedDomain)
                }
                onCancel={massCancel}
                okText='Yes'
                cancelText='No'
              >
                <Button
                  type='primary'
                  shape='round'
                  icon={<CheckCircleOutlined />}
                  size='middle'
                  className='product-head-action-button'
                  disabled={!hasSelected}
                >
                  Delete Domain
                </Button>
              </Popconfirm>
              <span
                style={{ marginLeft: 8, color: "#c2136b", fontWeight: 600 }}
              >
                {hasSelected
                  ? `Selected ${selectedDomain.selectedDomain.length} domain`
                  : ""}
              </span>
            </Col>
          )}

        <Col xs={10} sm={8} md={6} lg={4} xl={3}>
          <Button
            type='primary'
            onClick={() => handleCreateDomainModal({}, "new")}
          >
            Create New Domain
          </Button>
        </Col>
        <Col xs={14} sm={16} md={12} lg={7} xl={6}>
          <Search
            placeholder='Search domain'
            enterButton
            name='searchProduct'
            onSearch={(e) => {
              handleDomainSearch(e);
            }}
          />
        </Col>
      </Row>
      <Table
        rowSelection={domainSelection}
        rowKey='id'
        onRow={(record) => ({
          onClick: () => {
            domainClicked(record);
          },
        })}
        dataSource={tableData}
        columns={columns}
        loading={tableLoading}
        pagination={{
          defaultPageSize: 30,
          total: tableData.length,
          showSizeChanger: false,
          position: ["bottomCenter"],

          size: "large",
          showQuickJumper: true,
        }}
        rowClassName={(record, index) => {
          return index % 2 === 0
            ? "table-row-light order-list-table-row"
            : "table-row-dark order-list-table-row";
        }}
      />
    </>
  );
}

export default Domain;
