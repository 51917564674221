import React, { useState, useEffect } from "react";
// import { isMobile } from 'react-device-detect';
import axios from "axios";
import { message, Table, Input, Row, Col } from "antd";

const EmailDomains = () => {
  const { Search } = Input;
  const [tableData, setTableData] = useState([]),
    [tableLoading, setTableLoad] = useState(true);

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const columns = [
    {
      title: "id",
      dataIndex: "id",
      width: "3%",
      editable: false,
      render: (text) => <center>{text}</center>,
    },
    {
      title: "Domain Name",
      dataIndex: "domain_name",
      width: "20%",
      align: "left",
      editable: true,
    },
    {
      title: "Plan Name",
      dataIndex: "plan_name",
      width: "15%",
      align: "center",
      editable: true,
    },
    {
      title: "creation date",
      dataIndex: "creation_date",
      width: "10%",
      align: "center",
      editable: true,
      render: (text) =>
        new Date(parseInt(text) * 1000).toLocaleDateString("en-US"),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "16%",
      align: "center",
      render: (text) => <b>{text}</b>,
    },
    {
      title: "DNS1",
      dataIndex: "dns1",
      width: "18%",
      align: "right",
    },
    {
      title: "DNS2",
      dataIndex: "dns2",
      width: "18%",
      align: "right",
    },
  ];

  const handleDomainSearch = async (value) => {
    try {
      setTableLoad(true);
      if (value.length === 0) {
        const fetchTableData = await axios.get(
          "https://multidomain.zonedweb.com/api/email/domain",
          config
        );
        console.log(fetchTableData);
        if (fetchTableData.data.success === true) {
          setTableData([...fetchTableData.data.result]);
          setTableLoad(false);
        }
      }
      if (value.length > 0) {
        const fetchTableData = await axios.get(
          `https://multidomain.zonedweb.com/api/email/domain?search=${value}`,
          config
        );
        console.log(fetchTableData);
        if (fetchTableData.data.success === true) {
          setTableData([...fetchTableData.data.result]);
          setTableLoad(false);
        }
      }
    } catch (err) {
      console.log(err);

      message.error(
        `got error while fetching email domain list with Error: ${err}`
      );
    }
  };

  useEffect(() => {
    const kickStart = async () => {
      try {
        const fetchTableData = await axios.get(
          "https://multidomain.zonedweb.com/api/email/domain",
          config
        );
        console.log(fetchTableData);
        if (fetchTableData.data.success === true) {
          setTableData([...fetchTableData.data.result]);
          setTableLoad(false);
        }
      } catch (err) {
        console.log(err);

        message.error(
          `got error while fetching email domain list with Error: ${err}`
        );
      }
    };
    kickStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Row justify='end' style={{ padding: "10px" }}>
        <Col xs={14} sm={16} md={12} lg={7} xl={6}>
          <Search
            placeholder='Search Plan'
            enterButton
            name='searchPlan'
            onSearch={(e) => {
              handleDomainSearch(e);
            }}
          />
        </Col>
      </Row>
      <Table
        rowKey='id'
        dataSource={tableData}
        columns={columns}
        loading={tableLoading}
        pagination={{
          defaultPageSize: 30,
          total: tableData.length,
          showSizeChanger: false,
          position: ["bottomCenter"],
          hideOnSinglePage: true,
          size: "large",
          showQuickJumper: true,
        }}
        rowClassName={(record, index) => {
          return index % 2 === 0
            ? "table-row-light order-list-table-row"
            : "table-row-dark order-list-table-row";
        }}
      />
    </>
  );
};

export default EmailDomains;
