import React, { useState, useEffect } from "react";
// import { isMobile } from 'react-device-detect';
import axios from "axios";
import {
  message,
  Table,
  Input,
  Popconfirm,
  Form,
  Row,
  Col,
  Button,
  Modal,
} from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
const Cpanels = () => {
  const { Search } = Input;
  const [tableData, setTableData] = useState([]),
    [selectedDomain, setSelectedDomain] = useState([]),
    [tableLoading, setTableLoad] = useState(true),
    [isModalVisible, setIsModalVisible] = useState(false),
    [hasSelected, setHasSelected] = useState(false);

  const [form] = Form.useForm();

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const columns = [
      {
        title: "id",
        dataIndex: "id",
        width: "3%",
        editable: false,
        render: (text) => <center>{text}</center>,
      },
      {
        title: "Server Name",
        dataIndex: "servername",
        width: "10%",
        editable: true,
      },
      {
        title: "Host name",
        dataIndex: "hostname",
        width: "15%",
        editable: true,
      },
      {
        title: "Server IP",
        dataIndex: "serverip",
        width: "12%",
        editable: true,
        render: (text) => <center>{text}</center>,
      },
      {
        title: "Cpanel Port",
        dataIndex: "port",
        width: "9%",
        editable: true,
      },
      {
        title: "SSH port",
        dataIndex: "ssh_port",
        width: "9%",
        editable: true,
      },
      {
        title: "Local Ip",
        dataIndex: "localip",
        width: "11%",
        editable: true,
      },
      {
        title: "Username",
        dataIndex: "username",
        width: "8%",
        editable: true,
      },
      {
        title: "Token Key",
        dataIndex: "accesskey",
        width: "22%",
        editable: true,
      },
    ],
    onSelectChange = (selectedDomain) => {
      setSelectedDomain({ selectedDomain });
      setHasSelected(selectedDomain.length > 0);
    },
    domainSelection = {
      selectedDomain,
      onChange: onSelectChange,
    };
  const handleCreateDomainModal = () => {
      setIsModalVisible(true);
    },
    massCancel = (e) => {
      message.success("Canceled Cpanel Server delete");
    };
  const handleDomainSearch = async (value) => {
    try {
      setTableLoad(true);
      if (value.length === 0) {
        const fetchTableData = await axios.get(
          "https://multidomain.zonedweb.com/api/cloudflare/cpanels",
          config
        );
        console.log(fetchTableData);
        if (fetchTableData.data.success === true) {
          setTableData([...fetchTableData.data.result]);
          setTableLoad(false);
        }
      }
      if (value.length > 0) {
        const fetchTableData = await axios.get(
          `https://multidomain.zonedweb.com/api/cloudflare/cpanels?search=${value}`,
          config
        );
        console.log(fetchTableData);
        if (fetchTableData.data.success === true) {
          setTableData([...fetchTableData.data.result]);
          setTableLoad(false);
        }
      }
    } catch (err) {
      console.log(err);

      message.error(
        `got error while fetching Cpanel Server list with Error: ${err}`
      );
    }
  };
  const confirmMassDelete = async (e) => {
    try {
      for (let val of e) {
        const addDomainResult = await axios.delete(
          "https://multidomain.zonedweb.com/api/cloudflare/cpanels",
          {
            data: { id: val },
            ...config,
          }
        );
        if (addDomainResult.data.success === true) {
          setTableData((prev) => {
            const result = prev.filter((v) => v.id !== val);
            return [...result];
          });
        }
      }
      setSelectedDomain([]);
    } catch (err) {
      console.log(err);
      message.error(`got error adding Cpanel Server: ${err}`);
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const onFinish = async (e) => {
    try {
      const body = JSON.stringify(e);
      const addDomainResult = await axios.post(
        "https://multidomain.zonedweb.com/api/cloudflare/cpanels",
        body,
        config
      );
      if (
        !addDomainResult ||
        !addDomainResult.data ||
        addDomainResult.data.success !== true
      ) {
        message.error(addDomainResult.data.result);
      }
      if (
        addDomainResult &&
        addDomainResult.data &&
        addDomainResult.data.success === true
      ) {
        setTableData((prev) => [
          {
            id: addDomainResult.data.result,
            servername: e.servername,
            hostname: e.hostname,
            serverip: e.serverip,
            port: e.port,
            ssh_port: e.ssh_port,
            localip: e.localip,
            username: e.username,
            accesskey: e.accesskey,
          },
          ...prev,
        ]);
        form.resetFields();
        setIsModalVisible(false);
      }
    } catch (err) {
      console.log(err);

      message.error(`got error adding Cpanel Server: ${err}`);
    }
  };

  useEffect(() => {
    const kickStart = async () => {
      try {
        const fetchTableData = await axios.get(
          "https://multidomain.zonedweb.com/api/cloudflare/cpanels",
          config
        );
        console.log(fetchTableData);
        if (fetchTableData.data.success === true) {
          setTableData([...fetchTableData.data.result]);
          setTableLoad(false);
        }
      } catch (err) {
        console.log(err);

        message.error(
          `got error while fetching cpanel server list with Error: ${err}`
        );
      }
    };
    kickStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Modal
        title='Add new Cpanel Server'
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form
          name='addCpanel'
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 15 }}
          onFinish={onFinish}
          form={form}
          autoComplete='off'
        >
          <Form.Item
            label='Server Name'
            name='servername'
            rules={[
              { required: true, message: "Please input Cpanel Server name!" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label='server hostname'
            name='hostname'
            rules={[
              {
                required: true,
                message: "Please input Cpanel Server hostname!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Server external IP'
            name='serverip'
            rules={[
              {
                required: true,
                message: "Please input Cpanel server external IP!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Server port'
            name='port'
            rules={[{ required: true, message: "Please input Cpanel Port!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Server SSH Port'
            name='ssh_port'
            rules={[
              {
                required: true,
                message: "Please input Cpanel Server SSH Port",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Server Local Ip'
            name='localip'
            rules={[
              {
                required: true,
                message: "Please input Cpanel Server Local Ip Address",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Username'
            name='username'
            rules={[
              {
                required: true,
                message: "Please input client Cpanel Username",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Server access Token'
            name='accesskey'
            rules={[
              {
                required: true,
                message: "Please input Cpanel Server access token",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Row justify='end' style={{ padding: "10px" }}>
        {selectedDomain.selectedDomain &&
          selectedDomain.selectedDomain.length > 0 && (
            <Col xs={10} sm={8} md={6} lg={13} xl={15}>
              <Popconfirm
                title={`Confirm Delete ${
                  selectedDomain.selectedDomain
                    ? selectedDomain.selectedDomain.length
                    : 0
                } cpanel server ?`}
                onConfirm={() =>
                  confirmMassDelete(selectedDomain.selectedDomain)
                }
                onCancel={massCancel}
                okText='Yes'
                cancelText='No'
              >
                <Button
                  type='primary'
                  shape='round'
                  icon={<CheckCircleOutlined />}
                  size='middle'
                  className='product-head-action-button'
                  disabled={!hasSelected}
                >
                  Delete Cpanel Server
                </Button>
              </Popconfirm>
              <span
                style={{ marginLeft: 8, color: "#c2136b", fontWeight: 600 }}
              >
                {hasSelected
                  ? `Selected ${selectedDomain.selectedDomain.length} Cpanel Server`
                  : ""}
              </span>
            </Col>
          )}

        <Col xs={10} sm={8} md={6} lg={4} xl={3}>
          <Button
            type='primary'
            onClick={() => handleCreateDomainModal({}, "new")}
          >
            Create New Cpanel Server
          </Button>
        </Col>
        <Col xs={14} sm={16} md={12} lg={7} xl={6}>
          <Search
            placeholder='Search Cpanel Server'
            enterButton
            name='searchProduct'
            onSearch={(e) => {
              handleDomainSearch(e);
            }}
          />
        </Col>
      </Row>
      <Table
        rowSelection={domainSelection}
        rowKey='id'
        dataSource={tableData}
        columns={columns}
        loading={tableLoading}
        pagination={{
          defaultPageSize: 30,
          total: tableData.length,
          showSizeChanger: false,
          position: ["bottomCenter"],

          size: "large",
          showQuickJumper: true,
        }}
        rowClassName={(record, index) => {
          return index % 2 === 0
            ? "table-row-light order-list-table-row"
            : "table-row-dark order-list-table-row";
        }}
      />
    </>
  );
};

export default Cpanels;
