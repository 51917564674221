import React, { useState, useEffect } from 'react';
// import { isMobile } from 'react-device-detect';
import axios from 'axios';
import {
  message,
  Table,
  Input,
  Popconfirm,
  Form,
  Row,
  Col,
  Button,
  Modal,
} from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
function Zones() {
  const { Search } = Input;
  const [tableData, setTableData] = useState([]),
    [selectedDomain, setSelectedDomain] = useState([]),
    [tableLoading, setTableLoad] = useState(true),
    [isModalVisible, setIsModalVisible] = useState(false),
    [hasSelected, setHasSelected] = useState(false);

  const [form] = Form.useForm();

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const columns = [
      {
        title: 'id',
        dataIndex: 'id',
        width: '3%',
        editable: false,
        render: (text) => <center>{text}</center>,
      },
      {
        title: 'domain',
        dataIndex: 'domain',
        width: '20%',
        editable: true,
      },
      {
        title: 'Cloudflare',
        dataIndex: 'added',
        width: '5%',
        editable: true,
        render: (text) => <center>{text === 1 ? 'Yes' : 'No'}</center>,
      },
      {
        title: 'Zone ID',
        dataIndex: 'zoneid',
        width: '25%',
        editable: true,
        render: (text) => <center>{text}</center>,
      },
      {
        title: 'DNS1',
        dataIndex: 'dns1',
        width: '15%',
        editable: true,
      },
      {
        title: 'DNS2',
        dataIndex: 'dns2',
        width: '15%',
        editable: true,
      },
      {
        title: 'SSL',
        dataIndex: 'ssl',
        width: '5%',
        editable: true,
        render: (text) => <center>{text === 1 ? 'Yes' : 'No'}</center>,
      },
      {
        title: 'Cpanel User',
        dataIndex: 'cpaneluser',
        width: '10%',
        editable: true,
      },
    ],
    onSelectChange = (selectedDomain) => {
      setSelectedDomain({ selectedDomain });
      setHasSelected(selectedDomain.length > 0);
    },
    domainSelection = {
      selectedDomain,
      onChange: onSelectChange,
    };
  const handleCreateDomainModal = () => {
      setIsModalVisible(true);
    },
    massCancel = (e) => {
      message.success('Canceled zone delete');
    };
  const handleDomainSearch = async (value) => {
    try {
      setTableLoad(true);
      if (value.length === 0) {
        const fetchTableData = await axios.get('/api/cloudflare/zones', config);
        console.log(fetchTableData);
        if (fetchTableData.data.success === true) {
          setTableData([...fetchTableData.data.result]);
          setTableLoad(false);
        }
      }
      if (value.length > 0) {
        const fetchTableData = await axios.get(
          `/api/cloudflare/zones?search=${value}`,
          config
        );
        console.log(fetchTableData);
        if (fetchTableData.data.success === true) {
          setTableData([...fetchTableData.data.result]);
          setTableLoad(false);
        }
      }
    } catch (err) {
      console.log(err);

      message.error(`got error while fetching zone list with Error: ${err}`);
    }
  };
  const confirmMassDelete = async (e) => {
    try {
      for (let val of e) {
        const addDomainResult = await axios.delete('/api/cloudflare/zones', {
          data: { id: val },
          ...config,
        });
        if (addDomainResult.data.success === true) {
          setTableData((prev) => {
            const result = prev.filter((v) => v.id !== val);
            return [...result];
          });
        }
      }
      setSelectedDomain([]);
    } catch (err) {
      console.log(err);
      message.error(`got error adding zone: ${err}`);
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const onFinish = async (e) => {
    try {
      const body = JSON.stringify(e);
      const addDomainResult = await axios.post(
        '/api/cloudflare/zones',
        body,
        config
      );
      if (
        !addDomainResult ||
        !addDomainResult.data ||
        addDomainResult.data.success !== true
      ) {
        message.error(addDomainResult.data.result);
      }
      if (
        addDomainResult &&
        addDomainResult.data &&
        addDomainResult.data.success === true
      ) {
        setTableData((prev) => [
          {
            id: addDomainResult.data.result,
            domain: e.domain,
            accountid: e.accountid,
            zoneid: e.zoneid,
          },
          ...prev,
        ]);
        form.resetFields();
        setIsModalVisible(false);
      }
    } catch (err) {
      console.log(err);

      message.error(`got error adding zone: ${err}`);
    }
  };

  useEffect(() => {
    const kickStart = async () => {
      try {
        const fetchTableData = await axios.get('/api/cloudflare/zones', config);
        console.log(fetchTableData);
        if (fetchTableData.data.success === true) {
          setTableData([...fetchTableData.data.result]);
          setTableLoad(false);
        }
      } catch (err) {
        console.log(err);

        message.error(`got error while fetching zone list with Error: ${err}`);
      }
    };
    kickStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Modal
        title='Add new Zone'
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form
          name='addDomain'
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 15 }}
          onFinish={onFinish}
          form={form}
          autoComplete='off'
        >
          <Form.Item
            label='Domain'
            name='domain'
            rules={[{ required: true, message: 'Please input Domain name!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label='Zone ID'
            name='zoneid'
            rules={[
              { required: true, message: 'Please input cloudflare Zone Id!' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='DNS1'
            name='dns1'
            rules={[{ required: true, message: 'Please input zone DNS1!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='DNS2'
            name='dns2'
            rules={[{ required: true, message: 'Please input zone DNS2!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='CPanel User'
            name='cpaneluser'
            rules={[
              { required: true, message: 'Please input client Cpanel User' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Database USER'
            name='dbuser'
            rules={[
              { required: true, message: 'Please input client database User' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Database Password'
            name='dbpassword'
            rules={[
              {
                required: true,
                message: 'Please input client database Password',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Row justify='end' style={{ padding: '10px' }}>
        {selectedDomain.selectedDomain &&
          selectedDomain.selectedDomain.length > 0 && (
            <Col xs={10} sm={8} md={6} lg={13} xl={15}>
              <Popconfirm
                title={`Confirm Delete ${
                  selectedDomain.selectedDomain
                    ? selectedDomain.selectedDomain.length
                    : 0
                } zone ?`}
                onConfirm={() =>
                  confirmMassDelete(selectedDomain.selectedDomain)
                }
                onCancel={massCancel}
                okText='Yes'
                cancelText='No'
              >
                <Button
                  type='primary'
                  shape='round'
                  icon={<CheckCircleOutlined />}
                  size='middle'
                  className='product-head-action-button'
                  disabled={!hasSelected}
                >
                  Delete Zone
                </Button>
              </Popconfirm>
              <span
                style={{ marginLeft: 8, color: '#c2136b', fontWeight: 600 }}
              >
                {hasSelected
                  ? `Selected ${selectedDomain.selectedDomain.length} zone`
                  : ''}
              </span>
            </Col>
          )}

        <Col xs={10} sm={8} md={6} lg={4} xl={3}>
          <Button
            type='primary'
            onClick={() => handleCreateDomainModal({}, 'new')}
          >
            Create New Zone
          </Button>
        </Col>
        <Col xs={14} sm={16} md={12} lg={7} xl={6}>
          <Search
            placeholder='Search Zone'
            enterButton
            name='searchProduct'
            onSearch={(e) => {
              handleDomainSearch(e);
            }}
          />
        </Col>
      </Row>
      <Table
        rowSelection={domainSelection}
        rowKey='id'
        dataSource={tableData}
        columns={columns}
        loading={tableLoading}
        pagination={{
          defaultPageSize: 30,
          total: tableData.length,
          showSizeChanger: false,
          position: ['bottomCenter'],

          size: 'large',
          showQuickJumper: true,
        }}
        rowClassName={(record, index) => {
          return index % 2 === 0
            ? 'table-row-light order-list-table-row'
            : 'table-row-dark order-list-table-row';
        }}
      />
    </>
  );
}

export default Zones;
