import React, { useEffect, useState, Fragment } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  MenuOutlined,
  LoginOutlined,
  LogoutOutlined,
  FormOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';
import { Layout, Row, Col, Button } from 'antd';

const Navbar = (props) => {
  const { Header } = Layout,
    history = useHistory(),
    setLogout = async () => {
      localStorage.removeItem('isAuthenticated');
      localStorage.removeItem('token');
      localStorage.removeItem('email');
      props.setAuthen(false);
      history.push('/manage/login');
    };
  const { bugerClick } = props,
    current = useLocation(),
    authLinks = (
      <Button
        onClick={setLogout}
        icon={<LogoutOutlined />}
        type='link'
        className='header-link'
        name='login'
      >
        Logout
      </Button>
    ),
    guestLinks = (
      <>
        {' '}
        <Button
          onClick={() => {
            history.push('/manage/register');
          }}
          icon={<FormOutlined />}
          type='link'
          className='header-link'
        >
          Register
        </Button>{' '}
        /
        <Button
          icon={<LoginOutlined />}
          type='link'
          className='header-link'
          onClick={() => {
            history.push('/manage/login');
          }}
        >
          Login
        </Button>{' '}
      </>
    ),
    [currentPage, setCurrentPage] = useState('');

  useEffect(() => {
    let cp = '';
    current.pathname === '/manage/login' && (cp = 'Login');
    current.pathname === '/manage/register' && (cp = 'Sign Up');
    current.pathname === '/manage' && (cp = 'Dashboard');
    current.pathname === '/manage/domains' && (cp = 'Domains');
    current.pathname === '/manage/zones' && (cp = 'zones');

    setCurrentPage(cp);
  }, [current]);
  useEffect(() => {
    if (localStorage.getItem('isAuthenticated')) {
      props.setAuthen(true);
    }
  }, []);
  return (
    <Header className='site-layout-header'>
      <Row>
        <Col xs={12} lg={5}>
          <MenuOutlined
            style={{ fontSize: '23px', marginLeft: '5px' }}
            onClick={bugerClick}
          />
          <span style={{ marginLeft: '5px', marginRight: '10px' }}>
            {currentPage.toUpperCase()}
          </span>
        </Col>

        <Col
          lg={{ span: 6, offset: 13 }}
          xs={12}
          style={{ textAlign: 'right' }}
        >
          <Fragment>{props.isAuthenticated ? authLinks : guestLinks}</Fragment>
        </Col>
      </Row>
    </Header>
  );
};

export default Navbar;
