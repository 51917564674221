import React, { useEffect, useState } from "react";
import { Collapse, Switch, Row, Col, message } from "antd";
import { lime } from "@ant-design/colors";
import axios from "axios";
const { Panel } = Collapse;

const Settings = () => {
  const [writeChecked, setWriteChecked] = useState();
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const onChange = async (e) => {
    try {
      setWriteChecked(e);
      const updateChmodResult = await axios.post(
        "https://multidomain.zonedweb.com/api/settings/chmod",
        { allow: e },
        config
      );
    } catch (err) {
      console.log(err);

      message.error(
        `got error while updating allow write status with Error: ${err}`
      );
    }
  };
  useEffect(() => {
    const kickStart = async () => {
      try {
        const checkChmod = await axios.get(
          "https://multidomain.zonedweb.com/api/settings/chmod",
          config
        );
        console.log(checkChmod.data.result);
        if (checkChmod.data.success === true) {
          if (checkChmod.data.result === "enabled") {
            setWriteChecked(true);
          }
        }
      } catch (err) {
        console.log(err);

        message.error(
          `got error while fetching allow write status with Error: ${err}`
        );
      }
    };
    kickStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Collapse defaultActiveKey={["1"]} bordered={true}>
      <Panel
        header='Security Setting'
        key='1'
        style={{ backgroundColor: lime[8], color: "white" }}
        className='settingPanel'
      >
        <Row justify='space-around' style={{ paddingTop: "16px" }}>
          <Col xs={24} sm={24} md={20}>
            <p>
              Turn on stagging file system write protection to prevent hacking{" "}
              <Switch checked={writeChecked} onChange={onChange} />
            </p>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
};

export default Settings;
