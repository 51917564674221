import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { Layout, Menu, Avatar, Typography } from 'antd';
import {
  DashboardOutlined,
  MailOutlined,
  ShoppingCartOutlined,
  GlobalOutlined,
  SettingOutlined,
} from '@ant-design/icons';

const Sidebar = (props) => {
  const { collapsed, onCollapse } = props,
    { Sider } = Layout,
    { Text } = Typography,
    { SubMenu } = Menu,
    [collapsedWidth, setCollapsedWidth] = useState(60),
    history = useHistory(),
    [showPhoneNo, setShowPhoneNo] = useState(false),
    currPath = history.location.pathname,
    [title, setTitle] = useState(null),
    email = localStorage.getItem('email');

  useEffect(() => {
    onCollapse(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (collapsed === true || collapsed === undefined) {
      setShowPhoneNo(false);
    } else {
      setTimeout(() => {
        setShowPhoneNo(collapsed ? false : true);
      }, 200);
    }
  }, [collapsed]);

  const handleMenuClick = (e) => {
    setTitle(e.key);
    onCollapse(true);
    if (currPath !== e.key) return history.push(e.key);
  };
  return (
    <Sider
      breakpoint='xl'
      // collapsedWidth='0'
      collapsible={true}
      width={140}
      collapsed={collapsed}
      collapsedWidth={collapsedWidth}
      // trigger={null}
      onBreakpoint={(broken) => {
        if (broken === true) {
          setCollapsedWidth(0);
        }
        if (broken === false) {
          setCollapsedWidth(70);
        }
      }}
      onCollapse={onCollapse}
      defaultCollapsed={true}
    >
      <div className='logo'>
        <Avatar size={42} src='/manage/pngegg.png' />
        {localStorage.getItem('isAuthenticated') &&
        localStorage.getItem('isAuthenticated') === 'true' ? (
          <div className={showPhoneNo ? 'avatarText' : 'avatarTextHide'}>
            <Text strong={true} type='avatarText'>
              {email && `Hello ${email.substring(0, email.lastIndexOf('@'))}`}
            </Text>
          </div>
        ) : (
          ''
        )}
      </div>
      <Menu
        onClick={handleMenuClick}
        theme='dark'
        mode='inline'
        selectedKeys={[title]}
      >
        <Menu.Item icon={<DashboardOutlined />} key='/manage'>
          Dashboard
        </Menu.Item>
        <SubMenu key='wordpress' icon={<GlobalOutlined />} title='Wordpress'>
          <Menu.Item key='/manage/domains'>Domains</Menu.Item>
          <Menu.Item key='/manage/zones'>Zones</Menu.Item>
          <Menu.Item key='/manage/cpanels'>Cpanels</Menu.Item>
          <Menu.Item key='/manage/videolinks'>Video Add</Menu.Item>
        </SubMenu>
        <SubMenu key='email' icon={<MailOutlined />} title='Email'>
          <Menu.Item key='/manage/emailplans'>Email Plans</Menu.Item>
          <Menu.Item key='/manage/emaildomains'>Email Domains</Menu.Item>
        </SubMenu>

        <SubMenu key='magento' icon={<ShoppingCartOutlined />} title='Magento'>
          <Menu.Item key='/manage/magento_source'>
            Magento Theme Source
          </Menu.Item>
          <Menu.Item key='/manage/magento_template'>Magento Template</Menu.Item>
        </SubMenu>
        <Menu.Item icon={<SettingOutlined />} key='/manage/settings'>
          Settings
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default Sidebar;
