import React from 'react';
import { isLoggin } from './util/IsLoggin';
import { Redirect } from 'react-router-dom';
import { Area, Column, Pie } from '@ant-design/charts';
import { Row, Col, Typography } from 'antd';

const Main = () => {
  const logedin = isLoggin();
  const { Title } = Typography;
  if (!logedin) {
    return <Redirect to='/manage/login' />;
  }
  const incomingData = [
    { month: 'Jan', value: 10000 },
    { month: 'Feb', value: 12000 },
    { month: 'Mar', value: 13000 },
    { month: 'Apr', value: 15000 },
    { month: 'May', value: 18000 },
    { month: 'Jun', value: 20000 },
    { month: 'Jul', value: 23000 },
    { month: 'Aug', value: 27000 },
    { month: 'Sep', value: 12000 },
    { month: 'Oct', value: 18000 },
    { month: 'Nov', value: 33000 },
    { month: 'Dec', value: 35000 },
  ];
  const incomingConfig = {
    data: incomingData,
    height: 250,
    xField: 'month',
    yField: 'value',
    xAxis: {
      range: [0, 1],
      tickCount: 12,
    },
    areaStyle: function areaStyle() {
      return { fill: 'l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff' };
    },
  };
  const salesData = [
    { month: 'Jan', value: 30000 },
    { month: 'Feb', value: 42000 },
    { month: 'Mar', value: 33000 },
    { month: 'Apr', value: 25000 },
    { month: 'May', value: 58000 },
    { month: 'Jun', value: 60000 },
    { month: 'Jul', value: 33000 },
    { month: 'Aug', value: 67000 },
    { month: 'Sep', value: 62000 },
    { month: 'Oct', value: 78000 },
    { month: 'Nov', value: 83000 },
    { month: 'Dec', value: 95000 },
  ];
  const salesConfig = {
    data: salesData,
    height: 250,
    xField: 'month',
    yField: 'value',
    xAxis: { label: { autoRotate: false } },
    layout: 'vertical',
  };
  const imcomeType = [
    { Type: 'Template Sale', value: 25 },
    { Type: 'Hosting Sale', value: 35 },
    { Type: 'Domain Sale', value: 25 },
    { Type: 'Service', value: 15 },
  ];

  const Pieconfig = {
    appendPadding: 10,
    height: 250,
    data: imcomeType,
    angleField: 'value',
    colorField: 'Type',
    radius: 0.8,
    label: {
      type: 'outer',
      content: '{name} {percentage}',
    },
    interactions: [{ type: 'pie-legend-active' }, { type: 'element-active' }],
  };
  return (
    <>
      <Row
        gutter={8}
        justify='space-between'
        style={{ padding: '10px', backgroundColor: '#f0f0f0' }}
      >
        <Col
          xs={24}
          lg={12}
          style={{
            padding: '10px',
            marginBottom: '10px',
            border: '1px solid #fafafa',
            backgroundColor: '#fefefe',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
            borderRadius: '10px',
          }}
        >
          <Title level={5} style={{ textAlign: 'center' }}>
            Monthly Profit
          </Title>
          <Area {...incomingConfig} className='line-chart-class' />
        </Col>

        <Col
          xs={24}
          lg={11}
          style={{
            padding: '10px',
            border: '1px solid #fafafa',
            backgroundColor: '#fefefe',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
            borderRadius: '10px',
          }}
        >
          <Title level={5} style={{ textAlign: 'center' }}>
            Monthly Sales
          </Title>
          <Column {...salesConfig} />
        </Col>
      </Row>
      <Row
        gutter={8}
        justify='space-between'
        style={{ padding: '10px', BackgroundColor: '#f0f0f0' }}
      >
        <Col
          xs={24}
          lg={12}
          style={{
            padding: '10px',
            marginBottom: '10px',
            border: '1px solid #fafafa',
            backgroundColor: '#fefefe',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
            borderRadius: '10px',
          }}
        >
          <Title level={5} style={{ textAlign: 'center' }}>
            Sales Type
          </Title>
          <Pie {...Pieconfig} />
        </Col>

        <Col
          xs={24}
          lg={11}
          style={{
            padding: '10px',
            border: '1px solid #fafafa',
            backgroundColor: '#fefefe',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
            borderRadius: '10px',
          }}
        >
          <Title level={5} style={{ textAlign: 'center' }}>
            Monthly Sales
          </Title>
          <Column {...salesConfig} />
        </Col>
      </Row>
    </>
  );
};

export default Main;
