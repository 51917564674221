import React from "react";
import { isLoggin } from "../util/IsLoggin";

import axios from "axios";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Row, Button, Col, Typography, Form, Input, message } from "antd";
import { blue } from "@ant-design/colors";
import { UserOutlined } from "@ant-design/icons";
import setAuthToken from "../util/setAuthToken";
function Login(props) {
  const logedin = isLoggin();
  const { Title, Text } = Typography;
  const history = useHistory();
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (logedin) {
    return <Redirect to='/manage' />;
  }
  const onFinish = async (value) => {
    try {
      const body = JSON.stringify(value);
      const loginResult = await axios.post(
        "https://multidomain.zonedweb.com/api/auth/",
        body,
        config
      );
      if (loginResult.data.success === true) {
        localStorage.setItem("token", loginResult.data.token);
        localStorage.setItem("isAuthenticated", true);
        localStorage.setItem("email", loginResult.data.email);
        setAuthToken(localStorage.token);
        message.success("Login successfully");
        props.setAuthen(true);
        history.push("/manage");
      }
    } catch (err) {
      console.log(err.response.data.error);
      message.error(err.response.data.error);
    }
  };
  return (
    <Row justify='space-around' align='middle' style={{ marginTop: "50px" }}>
      <Col xs={24} sm={9} lg={7} className='signinCol'>
        <img
          src='/manage/logo.png'
          style={{ margin: "0px auto 20px auto", display: "block" }}
          alt='logo'
        />
        <Title
          level={3}
          style={{ color: blue, marginBottom: "5px", marginLeft: "10px" }}
        >
          <Text style={{ color: blue[6] }}> Sign In</Text>
        </Title>
        <UserOutlined style={{ marginLeft: "10px" }} /> Sign In To Your Account
        <Form
          name='normal_login'
          className='login-form'
          scrollToFirstError
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 15,
          }}
          onFinish={onFinish}
          style={{ marginTop: "20px" }}
        >
          <Form.Item
            name='email'
            label='Email'
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              { required: true, message: "Please input your Email!" },
            ]}
            hasFeedback
            validateTrigger='onBlur'
          >
            <Input type='email' allowClear placeholder='Email@domain.com' />
          </Form.Item>

          <Form.Item
            name='password'
            label='Password'
            validateTrigger='onBlur'
            hasFeedback
            rules={[
              { required: true, message: "Please input your Password!" },
              { min: 6, message: "password must be minimum 6 characters." },
            ]}
          >
            <Input.Password placeholder='password' />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              xs: { span: 10, offset: 7 },
              sm: { span: 8, offset: 10 },
            }}
          >
            <Button
              type='primary'
              htmlType='submit'
              className='signup-form-button'
              size='large'
            >
              Login
            </Button>
          </Form.Item>
          <Text className='already-register-or-login'>
            Not have an account? <Link to='/manage/register'>Register</Link>
          </Text>
        </Form>
      </Col>
    </Row>
  );
}

export default Login;
